import React, {  Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
const PassengerModal = ({isPassengerModalOpen,togglePassengerModal,passengerData}) => {
    const {t}=useTranslation()
    // useEffect(() => {
    //     console.log(passengerData,'is passenger data in passenger modal')
    // })
    return (

        <Transition appear show={isPassengerModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={togglePassengerModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                   {t('activePlanes.passengerDetails')}
                                </Dialog.Title>
                                <div className="mt-4">
                                
                                        <table className='w-full table-auto'>

                                            <thead>
                                                {(passengerData && passengerData.length>0) && (
                                                    <tr>
                                                        <th className='text-center py-2'>{t('activePlanes.name')}</th>
                                                        <th className='text-center py-2'>{t('activePlanes.gender')}</th>
                                                        <th className='text-center py-2'>{t('activePlanes.contact')}</th>
                                                    </tr>)}
                                            </thead>
                                            <tbody>
                                                {(passengerData && passengerData.length>0 )? (
                                                    <>


                                                        {passengerData.map((passenger, index) => (
                                                            <tr key={index}>
                                                                <td className='text-center px-4 py-2'>{passenger.first_name}</td>
                                                                <td className='text-center px-4 py-2'>{passenger.gender}</td>
                                                                <td className='text-center px-4 py-2'>{passenger.phone}</td>
                                                            </tr>

                                                        ))}
                                                    </>

                                                ) : (
                                                    <tr>
                                                       {t('activePlanes.passengerErr')}
                                                    </tr>
                                                )


                                                }

                                            </tbody>
                                        </table>
                                
                                       

                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border  bg-white border-2 border-mobius-golden px-4 py-2 text-sm font-medium text-black hover:bg-mobius-golden focus:outline-none   hover:text-white "
                                        onClick={togglePassengerModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>



    )
}
export default PassengerModal