import React from "react"
import { useTranslation } from "react-i18next"
import '../../i18n'

const SummaryContent=({flightType,flightDay,origin,departure_time,Airline,destination,arrival_time,flight_time})=>{
    const {t}=useTranslation()
    return(
        <div className="flex  md:flex-row flex-col gap-4">
        <div className="md:border-r-2 border-b-2 md:border-b-0 border-r-0 flex flex-col gap-2 pr-4 border-mobius-golden">
        <div className="font-bold text-center md:text-left">
        {flightType}
        </div>
        <div className="text-center">
           {flightDay} 
        </div>


        <div className="flex md:flex-row flex-col items-center gap-[3rem] md:w-[500px]  w-auto width-full md:justify-between justify-center ">
            {/* first entry */}
            <div className="text-center md:items-start items-center flex md:flex-row flex-col gap-6 ">
                <div className="flex md:items-start items-center flex-col">
                <div className="text-center justify-center items-center flex gap-2">
                    <p className="text-lg border-r-2 border-gray-500 pr-2 text-black"> {origin}</p>
                    <p className="text-black text-lg">{departure_time}</p>
                </div>
                <p className="text-start text-nowrap">{Airline}</p>
                </div>

                {/* horizontal line */}
               
                <div className="flex md:w-[200px] w-[2px] h-[100px] md:h-[2px] justify-center items-center md:top-4 top-0 relative">
             <div className="md:w-[200px] w-[2px] bg-mobius-golden rounded-lg md:h-[2px] h-[100px]"></div>
                </div>
                <div className="text-center flex gap-2">
                    <p className="text-lg border-r-2 border-gray-500 pr-2 text-black">{destination} </p>
                    <p className="text-black text-lg">{arrival_time}</p>
                </div>
           </div>

        </div>
        </div>


        <div>
           <p className="text-lg text-nowrap ">{t('orderSummary.time')}</p> 
           <p className="text-center md:text-left">{flight_time} hrs</p>
        </div>
    </div>
    
    )
}
export default SummaryContent