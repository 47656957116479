import React from 'react'
import { useTranslation } from 'react-i18next';
import '../../i18n'

const PreviousNext=({innerprevious,innernext})=>{
   const {t}=useTranslation()

    return(
        <div className="md:w-1/2 mx-auto flex justify-center items-center mt-6 gap-4">
                                 <button
                                    onClick={innerprevious}
                                    className=" bg-mobuis-gray text-nowrap  hover:bg-mobius-golden hover:text-white py-2 text-mobius-golden rounded-lg md:w-1/4 w-[100px] transition duration-300 ease-in-out transform hover:scale-105 px-4 cursor-pointer"
                                 >
                                    {t('flightDetails.goBack')}
                                 </button>
                                 <button
                                    onClick={innernext}
                                    className=" bg-mobuis-gray  hover:bg-mobius-golden hover:text-white py-2 text-mobius-golden rounded-lg md:w-1/4 transition duration-300 w-[100px] ease-in-out transform hover:scale-105 cursor-pointer"
                                 >
                                     {t('flightDetails.next')}
                                 </button>
</div>
    )
}
export default PreviousNext;