import React, { useState, useEffect,useCallback } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Plane, DollarSign, RockingChair, Filter } from "lucide-react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import EarningCard from "../Acernity/EarningCard";
import EarningModal from "./EarningModal";
import { useTranslation } from 'react-i18next';
import '../../i18n'

import FilterModal from "./FilterModal"; // Import the FilterModal component

const EarningComponent = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
      if (i18n.isInitialized) {
        console.log(t('welcome')); // Log the translated welcome text
      }
    }, [t, i18n.isInitialized]); // Ensure it runs when translations are loaded
  

    const [modalOpen, setModalOpen] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);  // State for filter modal
    const [modalData, setModalData] = useState(null);
    const [earningData, setEarningData] = useState([]);
    const [filters, setFilters] = useState({});  // Store filter values
    const id = localStorage.getItem('USER_ID');
    const userId = parseInt(localStorage.getItem('USER_ID'), 10);
    const token = localStorage.getItem('ACCESS_TOKEN');
    
    const [fleetId, setFleetId] = useState('');
    const [fleetData, setFleetData] = useState([])
    const [ownerPlanes, setOwnerPlanes] = useState([]);
    const [planeDocs, setPlaneDocs] = useState([]);
    const [ownerId, setOwnerId] = useState('');
    const [planesData, setPlanesData] = useState([]);

    
   
useEffect(() => {
       
        const fetchOwnerData = async () => {
            try {
                const response = await axios.get('/api/accounts/owners', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (Array.isArray(response.data)) {
                    const owner = response.data.find(item => (item.user === userId))?.id || null;
                    setOwnerId(owner);
                } else {
                    console.error('Unexpected data format');
                }
            } catch (error) {
                console.error('Error fetching owner data:', error);
            }
        };

        if (token) {
            fetchOwnerData();
        }
    }, [token, userId]);
   

    useEffect(() => {
        const fetchOwnerPlanes = async () => {
            try {
                const response = await axios.get('/api/fleet/planes/', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOwnerPlanes(response.data);
                // console.log("planes data is",response.data)
            } catch (error) {
                console.error('There was an error fetching the user data!', error);
                toast.error('Could not load user information', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
            }
        };

        if (token) {
            fetchOwnerPlanes();
        }
    }, [token]);

    useEffect(() => {
        const fetchPlaneDocs = async () => {
            try {
                const response = await axios.get('api/media/plane-docs/',
                    {
                       headers: {
                        'Authorization': `Bearer ${token}`
                    }
                    }
                    
                );
                setPlaneDocs(response.data);
                // console.log(response.data,'is filtered planes data')
            } catch (error) {
                console.error('There was an error fetching the plane documents!', error);
                toast.error('Could not load plane documents', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
            }
        };

        if (token) {
            fetchPlaneDocs();
        }
    }, [token]);

  

const filterPlaneDocs = useCallback(async (docId) => {
    try {
        if(docId){
        const response = await axios.get(`api/media/public/plane-doc/${docId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;}
    } catch (error) {
        console.error('There was an error fetching the plane documents!', error);
        toast.error('Could not load plane documents', {
            position: 'bottom-right',
            autoClose: 2000,
        });
    }
}, [token]);


useEffect(() => {
    if (ownerId && ownerPlanes.length > 0 && planeDocs.length > 0) {
        const filteredPlanes = ownerPlanes.filter(plane => plane.fleet.owner === ownerId);
        const fetchPlaneData = async () => {
            const result = await Promise.all(
                filteredPlanes.map(async (plane) => {
                    const doc = planeDocs.find(doc => doc.plane === plane.id);
                    const planedoc = doc ? await filterPlaneDocs(doc.id) : null;

                    return {
                        planeId: plane.id,
                        planeModel: plane.planeModel,
                        planereg: plane.registrationNumber,
                        fleetName: plane.fleet.fleetName,
                        document: planedoc ? planedoc.document : null,
                        image: planedoc ? planedoc.image_url : null,
                        docId: doc ? doc.id : null
                    };
                })
            );
            setPlanesData(result);
            // console.log(result);
        };

        fetchPlaneData();
    }
}, [ownerId, ownerPlanes, planeDocs, filterPlaneDocs]);




  
    // Function to get owner Id
    const fetchOwnerData = async (token_t) => {
        try {
            const response = await axios.get('/api/accounts/owners',
                {
                    headers: {
                        'Authorization': `Bearer ${token_t}`
                    }
                }
            );
            if (Array.isArray(response.data)) {
                // setOwnerData(response.data);
                const owner = response.data.find(item => (item.user === userId))?.id || null;
                setOwnerId(owner);

                return owner;
            } else {
                console.error('Unexpected data format');
            }
        } catch (error) {
            console.error('Error fetching owner data:', error);
        }
    };

    fetchOwnerData(token);

    useEffect(() => {
        if (id && token) {
            axios
                .get(`/api/fleet/fleets/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const allFleetData = response.data;
                    const filteredFleetData = allFleetData.filter(fleet => fleet.owner === ownerId);
                    // console.log(filteredFleetData)
                    setFleetData(filteredFleetData);
                })
                .catch((error) => {
                    console.error('There was an error fetching the user data!', error);
                    toast.error('Could not load user information', {
                        position: 'bottom-right',
                        autoClose: 2000,
                    });
                });
        }
    }, [id, token, fleetId, ownerId]);


    const openModal = (data) => {
        setModalData(data);
        setModalOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalData(null);
        document.body.style.overflow = 'auto';
    };

    const applyFilter = (filterParams) => {
        setFilters(filterParams);
    };

    useEffect(() => {
        const BASE_URL = "/api";
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const params = {
            start_date: filters.startDate || '',
            end_date: filters.endDate || '',
            fleet_id: filters.fleetId || '',
            plane_id: filters.planeId || '',
        };

        axios.get(`${BASE_URL}/flights/owner-earnings/get_earnings/`, { headers, params })
            .then(response => {
                // console.log(params,'are params')
                const data = response.data;
                setEarningData(data);
                // console.log("Filtered Owner Earnings Data:", data);
            })
            .catch(error => {
                console.log(`Error: ${error.response.status}`);
            });
    }, [filters]);  // Re-fetch data when filters change

    const progressData = {
        totalEarnings: earningData.percentage_seats_sold !== undefined ? earningData.percentage_seats_sold : 0,
        seatEarnings: earningData.total_seats_offered && earningData.total_seats_sold ?
            Math.round((earningData.total_seats_offered / earningData.total_seats_sold) * 100) :
            0,
        planeEarnings: earningData.total_flights && earningData.total_full_planes_sold ?
            Math.round((earningData.total_flights / earningData.total_full_planes_sold) * 100) :
            0
    };

    return (
        <div className="flex flex-col gap-6">
            <div className="self-end group flex gap-2 cursor-pointer" onClick={() => setFilterModalOpen(true)}>
                <Filter />
                <p className="group-hover:text-black font-bold">{t('earnings.filter')}</p>
        
            </div>

            <EarningCard
                progress={<CircularProgressbar
                    value={progressData.totalEarnings}
                    text={`${progressData.totalEarnings}%`}
                    styles={buildStyles({
                        textColor: '#000',
                        pathColor: '#665926',
                        trailColor: '#d6d6d6',
                        textSize: '18px',
                    })}
                />}
                text={true}
                amount={earningData.earnings_after_fees}
                desc={t('earnings.feeEarnings')}
            />

            <div className="flex flex-col md:flex-row gap-6">
                <EarningCard
                    text={false}
                    icon={<DollarSign />}
                    amount={earningData.total_earnings}
                    desc={t('earnings.tEarnings')}
                />
                <EarningCard
                    text={false}
                    details={t('earnings.details')}
                    icon={<RockingChair />}
                    amount={earningData.seat_earnings}
                    desc={t('earnings.seatEarnings')}
                    onClick={() => openModal({
                        heading: t('earnings.seatEarnings'),
                        seatNumber: earningData.total_seats_offered,
                        totalSoldSeats: earningData.total_seats_sold,
                        totalText: t('earnings.offeredSeats'),
                        soldText: t('earnings.soldSeats'),
                        progressValue: progressData.seatEarnings
                    })}
                />
                <EarningCard
                    text={false}
                    details={t('earnings.details')}
                    icon={<Plane />}
                    amount={earningData.whole_plane_earnings}
                    desc={t('earnings.planeEarnings')}
                    onClick={() => openModal({
                        heading: t('earnings.planeEarnings'),
                        seatNumber: earningData.total_flights,
                        totalSoldSeats: earningData.total_full_planes_sold,
                        totalText: t('earnings.offeredPlanes'),
                        soldText:  t('earnings.soldPlanes'),
                        progressValue: progressData.planeEarnings
                    })}
                />
            </div>

            {modalOpen && (
                <EarningModal closeModal={closeModal}  data={modalData}>
                    <CircularProgressbar
                        value={modalData.progressValue}
                        text={`${modalData.progressValue}%`}
                        styles={buildStyles({
                            textColor: '#000',
                            pathColor: '#665926',
                            trailColor: '#d6d6d6',
                            textSize: '18px',
                        })}
                    />
                </EarningModal>
            )}

            {filterModalOpen && (
                <FilterModal closeModal={() => setFilterModalOpen(false)} fleetData={fleetData} planesData={planesData} applyFilter={applyFilter} />
            )}
        </div>
    );
};

export default EarningComponent;
