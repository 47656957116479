import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "@/components/Loader/Loading";
import { Popover } from "@headlessui/react";
import { useAuth } from "@/components";
import TripComponent from "./TripComponent";
import { ReservationData } from "@/components/data/ReservationData";
import { useTranslation } from "react-i18next";
import '../../i18n'

const MyTrips = () => {
  // 1B4CC196
  // Base URL of your API
  const {t} = useTranslation()
  const baseURL = 'api/flights/reservations/';
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useAuth();
  let navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  // State to store the reservation code entered by the user
  const [reservationCode, setReservationCode] = useState('');

  // Function to fetch reservation details
  const fetchReservationDetails = async () => {
    if (!reservationCode) {
      toast.error('Reservation code is required', {
        position: 'bottom-right',
        autoClose: 2000,
      });
      return
    }
    setIsLoading(true)
    try {

      // Make the GET request to fetch reservation details by reservation code
      const response = await axios.get(`${baseURL}${reservationCode}/`);

      // Check if the response is successful
      if (response.status === 200) {
        console.log('Reservation details fetched successfully!');
        setIsLoading(false)
        navigate('/my-trips/trip-details', { state: { resDetails: response.data } })
        // Set the fetched data to the state

      } else {
        console.error('Failed to fetch reservation details:', response.data);
        toast.error('Cannot find the reservation details', {
          position: 'bottom-right',
          autoClose: 2000,
        });
        setIsLoading(false)
      }
    } catch (error) {
      // Catch and log any errors that occur during the request
      console.error('Error fetching reservation details:', error.response ? error.response.data : error.message);
      toast.error('Cannot find the reservation details please enter valid Code', {
        position: 'bottom-right',
        autoClose: 2500,
      });
    }
  };






   // useEffect(() => {
  //   const base_url = '/api/flights/';



  //   // The endpoint for retrieving user reservations
  //   const user_reservations_url = `${base_url}user-reservations/`;



  //   // Your bearer token




  //   // Optional query parameters
  //   const params = {
  //     active: 'false' // Only retrieve active reservations; adjust as needed
  //   };



  //   // Axios request to retrieve user reservations
  //   axios.get(user_reservations_url, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     },
  //     params: params
  //   })
  //     .then(response => {
  //       console.log('User Reservations:', response.data);
  //     })
  //     .catch(error => {
  //       if (error.response) {
  //         // The server responded with a status code outside the range of 2xx
  //         console.error('Error retrieving user reservations:', error.response.data);
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.error('No response received:', error.request);
  //       } else {
  //         // Something happened in setting up the request
  //         console.error('Error setting up request:', error.message);
  //       }
  //     });
  // })

  const login = () => {
    navigate('/login')
  }


  // Handle input change for the reservation code
  const handleInputChange = (e) => {
    setReservationCode(e.target.value);
  };

  const showDetails = (id) => {
    navigate('/my-trips/trip-details', { state: { resDetails: ReservationData[id] } })
  }
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (




        <div className="p-8 mt-6 px-12 min-h-[400px]">


          {!isLoggedIn ? (
            <div>
              <div className="text-2xl font-bold">
                {t('myFlights.flightText')}
              </div>

              <div className="flex items-center mt-4 justify-between w-[30%]">
                <p> {t('myFlights.signInText')}</p>
                <button onClick={login} className="w-[90px] border-2 h-[35px] border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full">
                {t('myFlights.signIn')}
                </button>
              </div>
              <div className="mt-12">
                <div className="flex items-center">
                  <p className="text-lg">{t('myFlights.enterRes')}</p>
                  <div className="relative ml-2">
                    <Popover className="relative">
                      <>
                        <Popover.Button
                          onMouseEnter={() => setIsOpen(true)}
                          onMouseLeave={() => setIsOpen(false)}
                          className="text-[10px] p-1 rounded-[100%] w-[20px] h-[20px] bg-mobius-golden text-white cursor-pointer focus:outline-none"
                        >
                          ?
                        </Popover.Button>
                        {isOpen && (
                          <Popover.Panel
                            onMouseEnter={() => setIsOpen(true)}
                            onMouseLeave={() => setIsOpen(false)}
                            className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-48 p-2 text-sm text-white bg-gray-700 rounded-md opacity-100 transition-opacity duration-300"
                          >
                           {t('myFlights.resText')}
                          </Popover.Panel>
                        )}
                      </>
                    </Popover>
                  </div>
                </div>

                <div className="flex justify-between w-[30%] mt-2 items-center">
                  <input
                    type="text"
                    className="w-[200px] py-2 h-[30px] px-2 text-md rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                    value={reservationCode}
                    onChange={handleInputChange}
                    placeholder={t('myFlights.resCode')}
                  />
                  <button
                    onClick={fetchReservationDetails}
                    className="w-[90px] border-2 h-[35px] border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full"
                  >
                    {t('myFlights.search')}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-h-[400px]  overflow-y-auto no-scrollbar">
              <div className="flex justify-between">
                <div className="text-2xl font-bold">{t('myFlights.flightText')}</div>
                <div className="">
                  <div className="flex items-center">


                  </div>

                  <div className="flex justify-between gap-6  mt-2 items-center">
                    <input
                      type="text"
                      className="w-[200px] py-2 h-[30px] px-2 text-md rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
                      value={reservationCode}
                      onChange={handleInputChange}
                      placeholder={t('myFlights.resCode')}
                    />
                    <button
                      onClick={fetchReservationDetails}
                      className="w-[90px] border-2 h-[35px] border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full"
                    >
                     {t('myFlights.search')}
                    </button>
                  </div>
                </div>
              </div>

              <div className="md:w-[90%] flex mt-8 flex-col gap-6 w-full mx-auto">
                {ReservationData.map((reservation, index) => (
                  <>

                    {reservation.flights[1] ? (
                      <div>
                        <TripComponent
                          originCity={reservation?.flights[1].origin_details.city}
                          destinationCity={reservation?.flights[1].destination_details.city}
                          origin={reservation?.flights[1].origin_details.iata_code}
                          destination={reservation?.flights[1].destination_details.iata_code}
                          arrival={reservation?.flights[1].arrival_time.slice(0, 5)}
                          departure={reservation?.flights[1].departure_time.slice(0, 5)}
                          flightStatus={reservation?.flights[1].status}
                          airline={reservation?.flights[1].airline}
                          Date={reservation?.flights[1].departure_date}
                          seatNo={reservation?.tickets[1].seat_number}
                          flightNo={reservation?.tickets[1].flight}
                          dataId={index}
                          isSmall={false}
                          showDetails={showDetails}
                        />
                        <TripComponent
                          originCity={reservation?.flights[0].origin_details.city}
                          destinationCity={reservation?.flights[0].destination_details.city}
                          origin={reservation?.flights[0].origin_details.iata_code}
                          destination={reservation?.flights[0].destination_details.iata_code}
                          arrival={reservation?.flights[0].arrival_time.slice(0, 5)}
                          departure={reservation?.flights[0].departure_time.slice(0, 5)}
                          flightStatus={reservation?.flights[0].status}
                          airline={reservation?.flights[0].airline}
                          Date={reservation?.flights[0].departure_date}
                          seatNo={reservation?.tickets[0].seat_number}
                          flightNo={reservation?.tickets[0].flight}
                          dataId={index}
                          isSmall={false}
                          showDetails={showDetails}
                        />

                      </div>
                    ) : (
                      <div>
                        <TripComponent
                          originCity={reservation?.flights[0].origin_details.city}
                          destinationCity={reservation?.flights[0].destination_details.city}
                          origin={reservation?.flights[0].origin_details.iata_code}
                          destination={reservation?.flights[0].destination_details.iata_code}
                          arrival={reservation?.flights[0].arrival_time.slice(0, 5)}
                          departure={reservation?.flights[0].departure_time.slice(0, 5)}
                          flightStatus={reservation?.flights[0].status}
                          airline={reservation?.flights[0].airline}
                          Date={reservation?.flights[0].departure_date}
                          seatNo={reservation?.tickets[0].seat_number}
                          flightNo={reservation?.tickets[0].flight}
                          dataId={index}
                          isSmall={false}
                          showDetails={showDetails}
                        />
                      </div>
                    )}
                  </>

                ))}


              </div>

            </div>
          )}

        </div>
      )


      }


    </div>

  );
};

export default MyTrips;
