import { Tab } from '@headlessui/react';
import { useEffect, useState} from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import FlightComponent from './FlightComponent';
import { AirportData } from '@/components/data/AirportData';
import { getSearchData } from '@/components/NewLandingPage/utils/storage';
import NavbarDesktop from './navbar-mobile';
import NavbarMobile from './navbar-desktop';
import { useTranslation } from 'react-i18next';
import '../../i18n'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const BookFlight = () => {
  const {t} = useTranslation()
  const [flightsData, setFlightsData] = useState([]);
  const [filteredFlights, setFilteredFlights] = useState({});
  const originCity=localStorage.getItem('origin')
  const destinationCity=localStorage.getItem('destination')
  const Origin_details = AirportData.find(airport => airport.city === originCity);
  const Destination_details = AirportData.find(airport => airport.city === destinationCity);
  // const [filter, setFilter] = useState(false);
  const searchData = getSearchData();
  const routerLocation = useLocation();
  const { isRound,passengers } = routerLocation.state || {};
  const departureDate=localStorage.getItem('departureDate')
  const returnDate=localStorage.getItem('returnDate')
  const returnData=JSON.parse(localStorage.getItem('returnData'));
  const departureBooked=localStorage.getItem('departureBooked') === 'true';
  const [origin, setOrigin] = useState((isRound && departureBooked) ? Destination_details.city:Origin_details.city);
  const [destination, setDestination] = useState((isRound && departureBooked) ? Origin_details.city:Destination_details.city);
 
  // const navigate = useNavigate();
  const centralDate=(departureBooked&&isRound)?returnDate:departureDate


  useEffect(()=>{
  
   
   console.log(departureBooked,'is departureBooked')
   console.log(returnData,'is returnData')
   console.log(searchData,'is search data')
   console.log(isRound,'is isRound in bookflight')

  })


  // const handleSearchShow = () => {
  //   const searchPage = false;
  //   navigate('/search-flight', { state: { searchPage } });
  // };
  // const handleFilter = () => {
  //   setFilter(!filter);
  // };
 const updateStartDate=(date)=>{
  setStartDate(date)
 }

  const calculateDateRange = (centralDate) => {
    const dateArray = [];
    for (let i = -3; i <= 3; i++) {
      const newDate = new Date(centralDate);
      newDate.setDate(newDate.getDate() + i);
      dateArray.push(newDate);
    }
    return dateArray;
  };

  const [startDate, setStartDate] = useState(calculateDateRange(centralDate)[0]);
  const [endDate, setEndDate] = useState(calculateDateRange(centralDate)[6]);

  useEffect(() => {
    const getFlights = async () => {
      try {
        const response = await axios.get('api/flights/public-flights/');
        if (response.status === 200) {
          setFlightsData(response.data);
        } else {
          throw new Error('Failed to fetch flight seats data');
        }
      } catch (error) {
        console.error('There was an error fetching the flights!', error);
      }
    };

    getFlights();
  }, []);

  useEffect(()=>{
    console.log(flightsData,'is flights data')
  })

  useEffect(() => {
    const filtered = flightsData.filter((flight) => {
      const departureDate = new Date(flight.departure_date);
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      const originMatch = origin === 'All Cities' || flight.origin_details.city === origin;
      const destinationMatch = destination === 'All Cities' || flight.destination_details.city === destination;
      const seatsAvailable=flight.available_seats>1
      return departureDate >= start && departureDate <= end && originMatch && destinationMatch && seatsAvailable;
    });

    const groupedByDay = {};
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const day = currentDate.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' });
      groupedByDay[day] = filtered.filter(flight =>
        new Date(flight.departure_date).toLocaleDateString('en-US') === currentDate.toLocaleDateString('en-US')
      );
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setFilteredFlights(groupedByDay);
  }, [flightsData, startDate, endDate, origin, destination]);

  const daysInRange = Object.keys(filteredFlights);
  const emptyFlightMessage =  t('bookFlight.emptyText');

  return (
    <div className='min-h-[100vh] overscroll-auto overflow-y-auto '>
     
      <NavbarDesktop
      startDate={startDate}
      setStartDate={updateStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      setOrigin={setOrigin}
      setDestination={setDestination}
      destination={destination}
      origin={origin}

      />
      <NavbarMobile
       startDate={startDate}
       setStartDate={updateStartDate}
       endDate={endDate}
       setEndDate={setEndDate}
       setOrigin={setOrigin}
       setDestination={setDestination}
       destination={destination}
       origin={origin}
      
      />
      
      <Tab.Group defaultIndex={3}>
        <div className="overflow-x-auto scrollbar-hide">
          <Tab.List className="flex bg-white md:border-y-0 w-full md:mt-8 mt-[7rem]">
            {daysInRange.map((day, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    'md:w-[250px] min-w-[170px] p-2 px-0 text-[2rem] border-y-2 text-sm leading-5 bg-mobius-gray mx-0 font-medium text-black border-[2px] border-x-[1px]',
                    selected ? 'border-0 outline-0 bg-gray-200' : 'hover:bg-white/[0.12]'
                  )
                }
              >
                <div className='flex  flex-col gap-2'>
                  <span>{day}</span>
                  <span>{`$${filteredFlights[day].length > 0
                    ? Math.min(...filteredFlights[day].map(flight => parseFloat(flight.seat_price))).toFixed(2)
                    : '0.00'}`}</span>
                </div>
              </Tab>
            ))}
          </Tab.List>
        </div>
        <Tab.Panels>
        {/* <div className='flex mx-8 mt-8 justify-between'>
            <div className='font-bold cursor-pointer' onClick={handleFilter}>
              Filter
            </div>
            <SearchIcon className='cursor-pointer ' onClick={handleSearchShow} />
          </div> */}
        <div>
          {(isRound && departureBooked)?(
            <div className='md:text-3xl text-2xl mt-12 font-bold text-center'>
             {t('bookFlight.returnText')}
            </div>
          ):(
            <div className='md:text-3xl text-2xl mt-12   font-bold text-center'>
               {t('bookFlight.departureText')}
            </div>
          )}
        </div>
         
          
          {daysInRange.map((day, index) => (
            <Tab.Panel
              key={index}
              className="p-3 bg-white h-fullrounded-xl "
            >
              <div className="mt-2 flex flex-col gap-4">
                {filteredFlights[day].length > 0 ? (
                  filteredFlights[day].map((flight) => (
                    <div key={flight.id}>
                      <FlightComponent
                        departure={flight.departure_time}
                        flightID={flight.id}
                        origin={flight.origin_details.iata_code}
                        availableSeats={flight.available_seats}
                        arrival={flight.arrival_time}
                        destination={flight.destination_details.iata_code}
                        seat_price={flight.seat_price}
                        plane_price={flight.whole_plane_price}
                        passengerNumber={passengers}
                        isRound={isRound}
                        departureBooked={departureBooked}
                      />
                    </div>
                  ))
                ) : (
                  <p className='text-center my-8 text-[1.3rem]'>{emptyFlightMessage}</p>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Outlet />
    </div>
  );
};

export default BookFlight;
