import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "Navbar": {
            "home": "Home",
            "about": "About Us",
            "trips": "My Trips",
            "contact": "Contact Us",
            "login": "Login",
            "reg": "Registration"
          },
          "Footer": {
            "intro": "Redefine first class with Mobius",
            "about": "About Us",
            "whoWeAre": "Who are We?",
            "allies": "Partners",
            "sus": "Sustainability",
            "customer": "Satisfied Customers",
            "info": "Information",
            "flightStatus": "Flight Status",
            "checkIn": "Check In",
            "booking": "Booking",
            "myFlights": "My Flights",
            "legal": "Legal",
            "privacyPolicy": "Privacy Policy",
            "cookiesPolicy": "Cookies Policy",
            "privacyNote": "Privacy Notice",
            "terms": "Terms and Conditions"
          },
          "Menu": {
            "profile": "Profile",
            "planeReg": "Register Plane",
            "flightReg": "Register Flight",
            "flightBook": "Book Flight",
            "seeFlight": "See Flights",
            "howItWork": "How This Works?",
            "logout": "LogOut"

          },
          "SearchFlight": {
            "single": "Single",
            "round": "Round",
            "origin": "Origin",
            "dest": "Destination",
            "passenger": "Passengers",
            "departure": "Departure Date",
            "return": "Return Date",
            "book": "Book Now"

          },
          "heroHome": {
            "upperText": "Elevate Your Travel Experience:Redefine",
            "lowerText": "Luxury in the Skies"
          },
          "jetSection": {
            "latest": "Latest generation aircraft",
            "safest": "Fly in the most modern and safest aircraft in the world",
            "catalog": "Take a look at our aircraft catalog.",
            "seeAll": "See All"

          },
          "quality": {
            "qualityText": "Quality guaranteed",
            "cert": "Certified Personal",
            "certDesc": "Our certified and highly trained pilots combine years of experience in private aviation to provide you with a safe and reliable journey.",
            "catering": "Professional Catering",
            "cateringDesc": "Enjoy a selection of exquisite dishes designed to satisfy your most discerning tastes while traveling in comfort and style.",
            "access": "Exclusive Access",
            "accessDesc": "Avoid long lines at airports and access your flight through exclusive entrances for Mobius customers",
            "executive": "Forget about business class",
            "executiveDesc": "Instead of wasting on business class, invest on exectutive flights"


          },
          "AboutUs": {
            "intro": "At Mobius, we believe in transcending the traditional boundaries of air travel. Founded with the vision of offering unmatched luxury, convenience and security,",
            "introS": "Mobius is your main option",
            "feature1": "Plan your flight according to your schedules and destinations preferred, with the flexibility that only private aviation can offer.",
            "feature2": "Enjoy the comfort and exclusivity of our private jets, equipped with the latest comforts and technology.",
            "joinText": "Join the Mobius Experience",
            "discoverText": "Discover the art of flying privately with Mobius. We promise you an experience as unique as you. Trust us to get you where you need to be, with elegance and ease.",
            "commitedText": "We are committed to reducing our environmental impact. We invest in the latest technologies and practices to ensure our operations are as green as possible. From fuel-efficient aircraft to sustainable onboard practices, we are dedicated to making a positive difference.",
            "reduceText": "According to estimates, taking advantage of an empty leg flight can reduce CO2 emissions per passenger by up to 30-50% compared to a traditional private flight, since fuel efficiency is maximized.",
            "optimizeText": "Furthermore, by optimizing the use of these flights, up to 15 tons of CO2 per year can be avoided for each jet that operates under this modality, depending on the frequency and route of the plane.",
            "contact": "Contact",
            "contactDesc": "For any questions, assistance or additional information, do not hesitate to contact us:",
            "openingText": "Opening Hours",
            "hours": "Monday to Friday from 10 to 11 am",
            "availableText": "We are available to help you with any need related to your flight or service with Mobius. We promise to respond to your queries as quickly as possible"


          },
          "PlaneIntro": {
            "welcome": "Welcome",
            "line1": "You are close to embarking on the journey that will give you the best for your plane and for you.",
            "line2": "Simply register your aircraft and you are ready to make this the new home for both you and your aircraft.",
            "begin": "Begin"
          },
          "FleetSelect": {
            "fleetName": " Write Your Fleet Name",
            "createText": "Create Fleet",
            "alreadyText": "Already have a Fleet?",
            "chooseText": "Choose Your Fleet",
            "exceptionText": "Unable to load fleet data. You need to Register first to get access.",
            "backText": "Go Back"


          },
          "seatSelect": {
            "line1": "How many seats your",
            "line2": "Aircraft have?",
            "add": "Add"
          },
          "airportSelect": {
            "line1": "Where is your Base?",
            "line2": "Select City to get Airports",
            "selectText": "Select a City"
          },
          "planeDetails": {

            "year": "Year",
            "yearDesc": "Select the year of making",
            "model": "Model",
            "modelDesc": "Select Model of your plane",
            "enterText": "Enter Plane Model",
            "tail": "Tail Number",
            "tailDesc": "Input the Tail Number of your plane"


          },
          "crewDetails": {
            "introText": "Please register your crew",
            "member": "member",
            "name": "Name",
            "nameEnter": "Enter Name",
            "lName": "Last Name",
            "lNameEnter": "Enter Last Name",
            "license": "License Number",
            "licenseEnter": "Enter License Number",
            "role": "Role",
            "role1": "First Officer",
            "role2": "Captain",
            "registeredMembers": "Registered Members",
            "noMembers": "Not any crew member registered yet",
            "checkMembers": "Check Registered Members",
            "fleets": "Fleets",
            "crew": "Crew"



          },
          "Document": {
            "line1": "Upload photos of your Aircraft",
            "line2": "Upload at least 5 photos of your aircraft as shown in the example",
            "uploadText": "Drag and drop or click to upload"
          },
          "ownerDoc": {
            "line1": "Upload your owner document",
            "line2": "The document must prove that you are legally authorized to manage flight operations on this aircraft",
            "upload": "Upload Document",
            "register": "Register Plane"
          },

          "flightDetails": {
            "chooseAircraftHeading": "Choose your Aircraft",
            "chooseAircraftDescription": "Select the aircraft you want to offer",
            "enterFlightDetailsHeading": "Enter flight details?",
            "originHeading": "Origin",
            "originSubheading": "Select Airport from your origin city",
            "destinationHeading": "Destination",
            "destinationSubheading": "Select Airport from your destination city",
            "departureDateHeading": "Departure Date",
            "departureDateDescription": "Enter your flight departure date",
            "departureTimeHeading": "Departure Time",
            "departureTimeDescription": "Enter departure time of your flight",
            "arrivalDateHeading": "Arrival Date",
            "arrivalDateDescription": "Enter your flight arrival date",
            "arrivalTimeHeading": "Arrival Time",
            "arrivalTimeDescription": "Enter Arrival time of your flight",
            "flightNumberHeading": "Flight Number",
            "flightNumberDescription": "Enter flight number",
            "airlineHeading": "Airline",
            "airlineDescription": "Enter the name of airline",
            "crewSelectionHeading": "Select Crew",
            "totalPriceHeading": "Enter the total price of the flight",
            "totalPriceDescription": "Remember that the flight is offered per passenger",
            "pricePlaceholder": "Price in MXN",
            "reviewRequestHeading": "We are reviewing your request",
            "reviewRequestDescription": "We will send you an email to your registered email address with the confirmation of the empty leg.",
            "uploadFlightPlanHeading": "Upload your flight Plan",
            "uploadFlightPlanDescription": "Add complete flight plan document",
            "dragDropUpload": "Drag and drop or click to upload",
            "uploadedDocument": "Uploaded Document",
            "registerFlight": "Register Flight",
            "flightNumber": "Flight Number",
            "airline": "Airline Name",
            "continue": "Continue",
            "goBack": "Go Back",
            "next": "Next"

          },
          "crewSelection": {
            "heading": "Crew Selection",
            "description": "Select Crew members for your flight or",
            "reg": "Register",
            "regMember": "Register",

            "placeholder": "Select a Crew Member",
            "maxSelectionAlert": "You can only select up to 3 crew members.",
            "role": "Role",
            "licenseNo": "License No.",
            "delete": "Delete",
            "noCrewSelected": "Please select at least 1 crew member."
          },
          "bookFlight": {
            "returnText": "Select Flight To Return",
            "departureText": "Select Departure Flight ",
            "emptyText": "No available flights for this date",
            "seatsText": "seats available",
            "details": "more details",
            "passengerInfo": "Passenger Information",
            "passengerDetail": "Enter details for Passenger No.",
            "passengerNumber": "Enter the number of passengers:",
            "samePassenger": "Passengers same as departure flight?",
            "continue": "Continue",
            "passengerDesc": "Please write the passenger information as displayed on official ID",
            "gender": "Gender",
            "genderSelect": "Select Gender",
            "male": "Male",
            "female": "Female",
            "other": "Other",
            "fieldsError": "Please fill out all fields.",
            "emailError": "Please enter a valid email address.",
            "fName": "First Name",
            "lName": "Last Name",
            "email": "Email",
            "phone": "Phone",
            "dob": "Date of Birth",
            "finish": "Finish"
          },
          "orderSummary": {
            "summaryText": "Order Summary",
            "time": "Flight Time",
            "departureFlight": "Departure",
            "returnFlight": "Return",
            "departurePlane": "Departure Plane",
            "returnPlane": "Return Plane",
            "view": "360 View",
            "crew": "Crew"
          },
          "priceBreak": {
            "priceText": "Price Breakdown",
            "flight": "Flights",
            "gas": "Gas Fee",
            "tax": "Taxes",
            "total": "Total",
            "pay": "Pay"
          },
          "confirmation": {
            "line1": "Congratulations! Your purchase has been processed successfully",
            "singleRes": "Your Reservation code is",
            "departureRes": "Your Reservation code for departure flight is",
            "returnRes": "and for return flight is",
            "line2": "This code has also been sent to your email along with your tickets"
          },
          "myFlights": {
            "to": "to",
            "city": "city",
            "fNumber": "Flight Number",
            "sNumber": "Seat Number",
            "status": "Status",
            "details": "Details",
            "flightText": "My Trips",
            "signInText": "Sign in to access all trips",
            "signIn": "Sign In",
            "enterRes": "Enter Reservation Number:",
            "resText": "Your reservation number can be found in the confirmation email.",
            "search": "Search",
            "resCode": "Reservation Code"
          },
          "signIn": {
            "welcome": "Welcome",
            "email": "Email",
            "pass": "Password",
            "google": "Sign in with Google",
            "continue": "Continue",
            "forgotPass": "Forgot your password?",
            "signupText": "Don't have an account? Sign up",
            "loginMessage": "Successful login",
            "loginError": "Login Failed,username or password is incorrect"
          },
          "signUp": {
            "name": "Name",
            "PSurName": "Paternal Surname",
            "MSurName": "Maternal Surname",
            "confirmPass": "Confirm Password",
            "dob": "Date of Birth",
            "phone": "Phone",
            "owner": "Register as Owner",
            "ownerDesc": "Mark it only if you have a plane and you want to register that.",
            "register": "Register",
            "account": "Do you already have an account?",
            "login": "Login",
            "regText": "Successful registration",
            "regError": "Registration Failed:",
            "passError": "The password must be at least 8 characters, one uppercase letter and one number.",
            "matchError": "Passwords do not match"

          },
          "Profile": {
            "account": "Account",
            "personalInfo": "Personal Information",
            "security": "Login and Security",
            "tickets": "Tickets",
            "earning": "Earnings",
            "prefs": "General Preferences",
            "fleet": "Fleet and Crew",
            "flights": "Flights"
          },
          "personalInfo": {
            "fSurName": "First Surname",
            "lSurName": "Last Surname",
            "owner": "Owner",
            "y": "yes",
            "n": "no"
          },
          "security": {
            "pass": "Password",
            "change": "Change",
            "twoStep": "Two Step Verification",
            "twoStepDesc": "Add extra security with two-step verification",
            "add": "Add"
          },
          "earnings": {
            "filter": "Filter",
            "myEarnings": "My Earnings",
            "tSales": "Total Sales",
            "feeEarnings": "Earning after Fees",
            "tEarnings": "Total Earnings",
            "details": "see details",
            "seatEarnings": "Seat Earnings",
            "offeredSeats": "Offered Seats:",
            "soldSeats": "Sold Seats:",
            "planeEarnings": "Whole Plane Earnings",
            "offeredPlanes": "Offered Planes:",
            "soldPlanes": "Sold Planes:",

          },
          "filter": {
            "filterEarnings": "Filter Earnings",
            "sDate": "Start Date",
            "eDate": "End Date",
            "fleetSelect": "Select Fleet",
            "fAll": "All Fleets",
            "fError": " Unable to load fleet data.",
            "planeSelect": "Select Plane",
            "pAll": "All Planes",
            "pError": "Unable to load Plane data.",
            "fButton": "Apply Filter"

          },
          "settings": {
            "language": "Preferred Language",
            "sp": "Spanish",
            "currency": "Preferred Currency",
            "peso": "Mexican Peso",
            "timeZone": "Time Zone",
            "change": "Change"
          },
          "activePlanes": {
            "seatsAvail": "seats available",
            "crew": "Crew",
            "passenger": "Passenger List",
            "view": "View",
            "status": "Status",
            "crewDetails": "Crew Details",
            "name": "Name",
            "role": "Role",
            "crewErr": "No Crew member registered for this flight",
            "close": "Close",
            "passengerDetails": "Passenger Details",
            "gender": "Gender",
            "contact": "Contact",
            "passengerErr": "No Passengers registered for this flight",
            "updateStatus": " Update Flight Status",
            "onTime": "On Time",
            "onFlight": "On Flight",
            "delay": "Delayed",
            "complete": "Completed",
            "update": "Update",
            "cancel": "Cancel"
          },
          "contactUs": {
            "first_line": "Let's Get in Touch",
            "second_line": "We are here to help you!",
            "subject": "Subject",
            "message": "Your Message",
            "submit": "Submit"
          },
          "inspection": {
            "heading": "Mobius Fly conducts inspections",
            "text": " on all aircraft registered on its platform to ensure they operate under the strictest aviation standards. This is reflected in the evaluations and certifications granted to our aircraft providers, as well as the training and courses completed by the pilots. We are creating a model within the community based on the principles of business aviation. By doing so, we promote the use of high-quality operational practices as the standard, maintaining safety and enhancing efficiency in every operation."
          },
          
            "terms_conditions": {
              "title": "Terms and conditions",
              "intro": "To the Users, we inform you that the following Terms and Conditions are applicable to the use of our services.",
              "section1": {
                "title": "Use and restrictions",
                "description": "Accessing or using the Portal expresses the User's full and unconditional adherence to these Terms and Conditions. Through the Portal, the User shall contract services and use a variety of tools and means for the completion of certain activities. The User may only access and use the Portal for personal use. The use of the Portal is subject to the following restrictions: The User shall not sell, license, distribute, reproduce, duplicate, modify, publish, copy, transmit or create derivative works from any content or services on the Portal without prior authorization."
              },
              "section2": {
                "title": "Restrictions",
                "description": "The User does not have the right to place hyperlinks within the Portal, to use the Portal's links, or to use the Portal’s services for purposes other than personal ones, without express authorization. The User may not alter or delete the content, notices, marks, or any other intellectual or proprietary property found in the Portal."
              },
              "section3": {
                "title": "Intellectual property",
                "description": "The intellectual property rights, industrial property rights regarding the Services, content, distinctive marks, and domain names, as well as the rights of use and exploitation thereof, including disclosure, publication, reproduction, distribution, and transformation, are the exclusive property of the Portal owners."
              },
              "section4": {
                "title": "Modifications to terms",
                "description": "The Portal reserves the right to modify the content of these Terms and Conditions at any time, as well as to update, modify or discontinue the Services provided without prior notice. It is the User’s responsibility to review these terms periodically."
              },
            
              "section5": {
                "title": "Third-party goods and services linked",
                "description": "The provision of information on the Portal or on other linked or connected sites does not imply endorsement, guarantee, sponsorship, or approval by the Company of such information, goods, and/or services. The availability of goods and/or services offered by third parties or through linked or connected sites is not the responsibility of the Company. Therefore, the Company shall not be liable to any authority of any kind for any matter related to the sale, consumption, distribution, delivery, availability, or provision of any goods and/or services offered by third parties or through linked or connected sites on the Portal. Regarding the Services and Content provided by third parties within or through links to the Portal (such as links, banners, and buttons), the Company limits itself exclusively, for the convenience of the User, to: (i) inform the User about them, and (ii) provide a means to contact the User with suppliers or sellers. The products and/or services marketed within the Portal and/or on third-party sites linked to the Portal are provided by independent merchants, and it shall not be understood in any case that they are the responsibility of the Company. There is no employment relationship, association, or partnership between the Company and such third parties. Any advice, statement, information, and content of linked third-party pages or within the Portal represent the opinions and judgments of such third parties; consequently, the Company shall not be liable for any damage or harm suffered by the User as a result of these."
              },
              "section6": {
                "title": "Confidentiality",
                "description": "The Company undertakes to keep confidential the information received from the User that has such a character in accordance with the applicable legal provisions in the United Mexican States; the Company assumes no obligation to keep confidential any other information provided by the User."
              },
              "section7": {
                "title": "Use of non-confidential information",
                "description": "By using the Portal, the User authorizes the Company, in an illustrative but not limited manner, to use, publish, reproduce, disclose, publicly communicate, and transmit non-confidential information, in terms of the Federal Law on Protection of Personal Data Held by Private Parties, the Federal Copyright Law, the Federal Law on Consumer Protection, and any other applicable Mexican legislation."
              },
              "section8": {
                "title": "Cookies",
                "description": "The User accessing the Portal agrees to receive files transmitted to them by the Company's servers. A 'Cookie' is a data file stored on the User's computer hard drive when they access the Portal. Such files may contain information such as the identification provided by the User or information to track the pages visited by the User. A Cookie cannot read data or information from the User's hard drive or read Cookies created by other sites or pages. Generally, Cookies are automatically accepted; the User can change their browser settings at any time. If the User decides to reject Cookies, certain sections of the Portal may not function optimally or may not work at all."
              },
              "section9": {
                "title": "Notice of privacy of personal data",
                "description": "All information that the Company collects from the User is treated with absolute confidentiality in accordance with the applicable legal provisions in Mexican legislation. To learn more about the protection of your personal data, please consult our Privacy Notice."
              },
              "section10": {
                "title": "Access keys",
                "description": "At all times, the User is solely and ultimately responsible for keeping confidential the access keys they may have and with which they access certain Services and Contents of the Portal."
              },
              "section11": {
                "title": "Accounts",
                "description": "By creating an account on the Portal, the User declares, under penalty of perjury, that they are at least 18 years old or the legal age of majority in their jurisdiction; the User acknowledges that they are responsible for any activity conducted with or through said account, and acknowledges that they are aware of and accept the conditions established in these Terms and Conditions and in the Privacy Notice."
              },
              "section12": {
                "title": "Modifications",
                "description": "The Company reserves the right to modify these Terms and Conditions at any time, without prior notice and/or the User's consent. Consequently, the User must read the Terms and Conditions carefully each time they intend to use the Portal. Certain Services and Contents offered to Users on and/or through the Portal are subject to specific conditions that replace, supplement, and/or modify these Terms and Conditions. As a result, the User must also carefully read the relevant specific conditions before accessing any of the Services and Contents."
              },
              "section13": {
                "title": "Applicable laws and jurisdiction",
                "description": "For the interpretation, compliance, and enforcement of these Terms and Conditions, the User agrees that the federal laws of the United Mexican States will apply, and the competent courts of San Pedro Garza García, Nuevo León, will have jurisdiction, expressly waiving any other jurisdiction that may correspond to them due to their present or future domiciles or for any other reason."
              },
              "section14": {
                "title": "Contact",
                "description": "The User may contact the Company staff at any time for any clarification, comment, question, and/or suggestion related to the Services and Contents, the Portal, and/or these Terms and Conditions at contacto@mobiusfly.com."
              }
            }
          
          











        }
      },

      sp: {
        translation: {
          "Navbar": {
            "home": "Inicio",
            "about": "Sobre nosotras",
            "trips": "Mis viajes",
            "contact": "Contacta ",
            "login": "Acceso",
            "reg": "Registro"
          },
          "Footer": {
            "intro": "Redefine la primera clase con Mobius",
            "about": "Nosotros",
            "whoWeAre": "¿Quienes Somos?",
            "allies": "Aliados",
            "sus": "Sustentabilidad",
            "customer": "Clientes Satisfechos",
            "info": "Información",
            "flightStatus": "Estatus de vuelo",
            "checkIn": "Registrarse",
            "booking": "Reserva",
            "myFlights": "Mis vuelos",
            "legal": "Legal",
            "privacyPolicy": "Política de Privacidad",
            "cookiesPolicy": "Política de cookies",
            "privacyNote": "Aviso de Privacidad",
            "terms": "Términos y Condiciones"
          },
          "Menu": {
            "profile": "Perfil",
            "planeReg": "Agregar Avión",
            "flightReg": "Agendar Vuelo",
            "flightBook": "Buscar Vuelo",
            "seeFlight": "Ver vuelos",
            "howItWork": "¿Cómo Funciona?",
            "logout": "Cerrar Sesion"

          },
          "SearchFlight": {
            "single": "Sencillo",
            "round": "Redondo",
            "origin": "Origen",
            "dest": "Destino",
            "passenger": "Pasajeros",
            "departure": "Fecha de salida",
            "return": "Fecha de regreso",
            "book": "Reserva ahora"

          },
          "heroHome": {
            "upperText": "Eleva tu Experiencia de Viaje:Redefine ",
            "lowerText": "el Lujo en los Cielos"
          },
          "jetSection": {
            "latest": "Aeronaves de última generación",
            "safest": "Vuela en las aeronaves más modernas y seguras del mundo",
            "catalog": "Echa un vistazo a nuestro catálogo de aeronaves.",
            "seeAll": " Ver Todas"

          },
          "quality": {
            "qualityText": "Calidad garantizada",
            "cert": "Personal certificado",
            "certDesc": "Nuestros pilotos certificados y altamente capacitados combinan años de experiencia en aviación privada para brindarle un viaje seguro y confiable.",
            "catering": "Catering profesional",
            "cateringDesc": "Disfrute de una selección de exquisitos platos diseñados para satisfacer sus gustos más exigentes mientras viaja con comodidad y estilo.",
            "access": "Acceso Exclusivo",
            "accessDesc": "Evita largas colas en los aeropuertos y accede a tu vuelo a través de entradas exclusivas para clientes de Mobius",
            "executive": "Olvídate de la clase ejecutiva",
            "executiveDesc": "En lugar de desperdiciar en clase ejecutiva, invierta en vuelos ejecutivos"
          },
          "AboutUs": {
            "intro": " En Mobius, creemos en trascender los límites tradicionales del viaje aéreo. Fundada con la visión de ofrecer un lujo,conveniencia y seguridad inigualables,",
            "introS": "Mobius es tu opción principal",
            "feature1": "Planifica tu vuelo de acuerdo a tus horarios y destinos preferidos, con la flexibilidad que solo la aviación privada puede ofrecer.",
            "feature2": "Disfrute de la comodidad y exclusividad de nuestros jets privados, equipados con las últimas comodidades y tecnología.",
            "joinText": "Únete a la Experiencia Mobius",
            "discoverText": "Descubre el arte de volar en privado con Mobius. Te prometemos una experiencia tan única como tú. Confía en nosotros para llevarte a donde necesitas estar, con elegancia y facilidad.",
            "commitedText": "Estamos comprometidos a reducir nuestro impacto ambiental. Invertimos en las últimas tecnologías y prácticas para garantizar que nuestras operaciones sean lo más ecológicas posible. Desde aeronaves eficientes en combustible hasta prácticas sostenibles a bordo, nos dedicamos a hacer una diferencia positiva.",
            "reduceText": "Según estimaciones, el aprovechamiento de un vuelo empty leg puede reducir hasta en un 30-50% las emisiones de CO2 por pasajero en comparación con un vuelo privado tradicional, ya que se maximiza la eficiencia del combustible.",
            "optimizeText": "Además, al optimizar el uso de estos vuelos, se pueden evitar hasta 15 toneladas de CO2 por año por cada jet que opera bajo esta modalidad, dependiendo de la frecuencia y la ruta del avión.",
            "contact": "Contacto",
            "contactDesc": "Para cualquier consulta, asistencia o información adicional, no dudes en contactarnos:",
            "openingText": "Horario de atención",
            "hours": "Lunes a Viernes de 10 a 11 am",
            "availableText": "Estamos disponibles para ayudarte con cualquier necesidad relacionada con tu vuelo o servicio con Mobius. Nos comprometemos a responder a tus consultas lo más rápido posible"


          },
          "PlaneIntro": {
            "welcome": "Bienvenido",
            "line1": "Estás cerca de emprender el viaje que te dará lo mejor para tu avión y para ti.",
            "line2": "Simplemente haz el registro de tu aeronave y estás listo para poder hacer de este la nueva casa tanto para ti, como para tu aeronave.",
            "begin": "Comenzar"
          },
          "FleetSelect": {
            "fleetName": "Escribe el nombre de tu flota",
            "createText": "Crear Flota",
            "alreadyText": "¿Ya tienes una flota?",
            "chooseText": "Elige tu flota",
            "exceptionText": "No se puede cargar la información de la flota. Debes registrarte primero para obtener acceso.",
            "backText": "Regresar"
          },

          "seatSelect": {
            "line1": "¿Cuántos asientos tiene tu",
            "line2": "aeronave?",
            "add": "Añadir"
          },
          "airportSelect": {
            "line1": "¿Dónde está tu base?",
            "line2": "Seleccione Ciudad para obtener Aeropuertos",
            "selectText": "Seleccione una ciudad"
          },
          "planeDetails": {
            "year": "Año",
            "yearDesc": "Selecciona el año de fabricación",
            "model": "Modelo",
            "modelDesc": "Selecciona el modelo de tu avión",
            "enterText": "Ingresar modelo plano",
            "tail": "Matrícula",
            "tailDesc": "Introduce el Matrícula de tu avión"
          },
          "crewDetails": {
            "introText": "Por favor registra a tu tripulante",
            "member": "miembro",
            "name": "Nombre",
            "nameEnter": "Ingresa el nombre",
            "lName": "Apellido",
            "lNameEnter": "Ingresa el apellido",
            "license": "Número de licencia",
            "licenseEnter": "Ingresa el número de licencia",
            "role": "Rol",
            "role1": "Primer Oficial",
            "role2": "Capitán",
            "registeredMembers": "Miembros Registrados",
            "noMembers": "No hay ningún miembro de la tripulación registrado aún",
            "checkMembers": "Ver Miembros Registrados",
            "fleets": "Flotas",
            "crew": "Multituds"
          },
          "Document": {
            "line1": "Sube las fotos de tu Aeronave",
            "line2": "Sube al menos 5 fotos de tu aeronave como se muestra en el ejemplo",
            "uploadText": "Arrastra y suelta o haz clic para cargar",

          },
          "ownerDoc": {
            "line1": "Sube tu documento de propietario",
            "line2": "El documento debe acreditar que está legalmente autorizado para gestionar las operaciones de vuelo en este avión.",
            "upload": "Subir documento",
            "register": "Registrar avión"
          },

          "flightDetails": {
            "chooseAircraftHeading": "Elige tu aeronave",
            "chooseAircraftDescription": "Selecciona la aeronave que deseas ofrecer",
            "enterFlightDetailsHeading": "¿Ingresar detalles del vuelo?",
            "originHeading": "Origen",
            "originSubheading": "Selecciona el aeropuerto de tu ciudad de origen",
            "destinationHeading": "Destino",
            "destinationSubheading": "Selecciona el aeropuerto de tu ciudad de destino",
            "departureDateHeading": "Fecha de salida",
            "departureDateDescription": "Ingresa la fecha de salida de tu vuelo",
            "departureTimeHeading": "Hora de salida",
            "departureTimeDescription": "Ingresa la hora de salida de tu vuelo",
            "arrivalDateHeading": "Fecha de llegada",
            "arrivalDateDescription": "Ingresa la fecha de llegada de tu vuelo",
            "arrivalTimeHeading": "Hora de llegada",
            "arrivalTimeDescription": "Ingresa la hora de llegada de tu vuelo",
            "flightNumberHeading": "Número de vuelo",
            "flightNumberDescription": "Ingresa el número de vuelo",
            "airlineHeading": "Aerolínea",
            "airlineDescription": "Ingresa el nombre de la aerolínea",
            "crewSelectionHeading": "Seleccionar tripulación",
            "totalPriceHeading": "Ingresa el precio total del vuelo",
            "totalPriceDescription": "Recuerda que el vuelo se ofrece por pasajero",
            "pricePlaceholder": "Precio en MXN",
            "reviewRequestHeading": "Estamos revisando tu solicitud",
            "reviewRequestDescription": "Te enviaremos un correo electrónico a la dirección registrada con la confirmación del tramo vacío.",
            "uploadFlightPlanHeading": "Sube tu plan de vuelo",
            "uploadFlightPlanDescription": "Agrega el documento completo del plan de vuelo",
            "dragDropUpload": "Arrastra y suelta o haz clic para cargar",
            "uploadedDocument": "Documento cargado",
            "registerFlight": "Registrar vuelo",
            "flightNumber": "Número de vuelo",
            "airline": "Nombre de la aerolínea",
            "continue": "Continuar",
            "goBack": "Regresar",
            "next": "Próximo"

          },
          "crewSelection": {
            "heading": "Selección de Tripulación",
            "description": "Selecciona los miembros de la tripulación para tu vuelo",
            "placeholder": "Selecciona un Miembro de la Tripulación",
            "reg": "Registrar",
            "regMember": "Registro",
            "maxSelectionAlert": "Solo puedes seleccionar hasta 3 miembros de la tripulación.",
            "role": "Rol",
            "licenseNo": "Número de Licencia",
            "delete": "Eliminar",
            "noCrewSelected": "Por favor selecciona al menos 1 miembro de la tripulación."
          },
          "bookFlight": {
            "returnText": "Seleccione Vuelo Para Regresar",
            "departureText": "Seleccione Vuelo de Salida ",
            "emptyText": "No hay vuelos disponibles para esta fecha",
            "seatsText": "asientos disponibles",
            "details": "más detalles",
            "passengerInfo": "Información del pasajero",
            "passengerDetail": "Ingrese los detalles del número de pasajero",
            "passengerNumber": "Ingrese el número de pasajeros:",
            "samePassenger": "¿Los mismos pasajeros que en el vuelo de salida?",
            "continue": "Continuar",
            "passengerDesc": "Por favor escriba la información del pasajero como se muestra en la identificación oficial.",
            "gender": "Género",
            "genderSelect": "Seleccionar Género",
            "male": "Masculino",
            "female": "Femenino",
            "other": "Otro",
            "fieldsError": "Por favor complete todos los campos.",
            "emailError": "Por favor ingrese una dirección de correo electrónico válida.",
            "fName": "Nombre",
            "lName": "Apellido",
            "email": "Correo Electrónico",
            "phone": "Teléfono",
            "dob": "Fecha de Nacimiento",
            "finish": "Finalizar"

          },
          "orderSummary": {
            "summaryText": "Resumen del pedido",
            "time": "Tiempo de vuelo",
            "departureFlight": "Vuelo de Ida",
            "returnFlight": "Vuelo de Regreso",
            "departurePlane": "Avión de Ida",
            "returnPlane": "Avión de Regreso",
            "view": "Vista 360",
            "crew": "la tripulación"


          },
          "priceBreak": {
            "priceText": "Desglose de Precios",
            "flight": "Vuelos",
            "gas": "Tarifa de Gas",
            "tax": "Impuestos",
            "total": "Total",
            "pay": "Pagar"
          },
          "confirmation": {
            "line1": "¡Felicidades! Tu compra ha sido procesada con éxito",
            "singleRes": "Tu código de reserva es ",
            "departureRes": "Tu código de reserva para el vuelo de ida es ",
            "returnRes": " y para el vuelo de regreso es",
            "line2": "Este código también ha sido enviado a tu correo electrónico junto con tus boletos"
          },
          "myFlights": {
            "to": "a",
            "city": "ciudad",
            "fNumber": "Número de Vuelo",
            "sNumber": "Número de Asiento",
            "status": "Estado",
            "details": "Detalles",
            "flightText": "Mis Viajes",
            "signInText": "Inicia sesión para acceder a todos los viajes",
            "signIn": "Iniciar Sesión",
            "enterRes": "Ingresa el Número de Reserva:",
            "resText": "Tu número de reserva se encuentra en el correo de confirmación.",
            "search": "Buscar",
            "resCode": "código de reserva"
          },
          "signIn": {
            "welcome": "Bienvenido",
            "email": "Email",
            "pass": "Contraseña",
            "google": "Iniciar sesión con Google",
            "continue": "Continuar",
            "forgotPass": "¿Olvidaste tu contraseña?",
            "signupText": "¿No tienes una cuenta? Inscribirse",
            "loginMessage": "Inicio de sesión exitoso",
            "loginError": "Error al iniciar sesión, el nombre de usuario o la contraseña son incorrectos"
          },
          "signUp": {
            "name": "Nombre",
            "PSurName": "Apellido Paterno",
            "MSurName": "Apellido Materno",
            "confirmPass": "Confirmar Contraseña",
            "dob": "Fecha de Nacimiento",
            "phone": "Teléfono",
            "owner": "Registrar como Propietario",
            "ownerDesc": "Márcalo solo si tienes un avión y deseas registrarlo.",
            "register": "Registrar",
            "account": "¿Ya tienes una cuenta?",
            "login": "Iniciar Sesión",
            "regText": "Registro exitoso",
            "regError": "Fallo en el registro:",
            "passError": "La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un número.",
            "matchError": "Las contraseñas no coinciden"
          },
          "Profile": {
            "account": "Cuenta",
            "personalInfo": "Información Personal",
            "security": "Inicio de Sesión y Seguridad",
            "tickets": "Boletos",
            "earning": "Ganancias",
            "prefs": "Preferencias Generales",
            "fleet": "Flota y Tripulación",
            "flights": "Vuelos"
          },
          "personalInfo": {
            "fSurName": "Primer Apellido",
            "lSurName": "Segundo Apellido",
            "owner": "Propietario",
            "y": "sí",
            "n": "no"
          },
          "security": {
            "pass": "Contraseña",
            "change": "Cambiar",
            "twoStep": "Verificación de dos pasos",
            "twoStepDesc": "Agregue seguridad adicional con la verificación en dos pasos",
            "add": "Agregar"
          },
          "earnings": {
            "filter": "Filtrar",
            "myEarnings": "Mis Ganancias",
            "tSales": "Ventas Totales",
            "feeEarnings": "Ganancias después de Tarifas",
            "tEarnings": "Ganancias Totales",
            "details": "ver detalles",
            "seatEarnings": "Ganancias por Asiento",
            "offeredSeats": "Asientos Ofrecidos:",
            "soldSeats": "Asientos Vendidos",
            "planeEarnings": "Ganancias de Todo el Avión",
            "offeredPlanes": "Aviones Ofrecidos",
            "soldPlanes": "Aviones Vendidos"
          },
          "filter": {
            "filterEarnings": "Filtrar Ganancias",
            "sDate": "Fecha de Inicio",
            "eDate": "Fecha de Fin",
            "fleetSelect": "Seleccionar Flota",
            "fAll": "Todas las Flotas",
            "fError": "No se pudo cargar los datos de la flota.",
            "planeSelect": "Seleccionar Avión",
            "pAll": "Todos los Aviones",
            "pError": "No se pudo cargar los datos del avión.",
            "fButton": "Aplicar Filtro"
          },
          "settings": {
            "language": "Idioma Preferido",
            "sp": "Español",
            "currency": "Moneda Preferida",
            "peso": "Peso Mexicano",
            "timeZone": "Zona Horaria",
            "change": "Cambiar"
          },
          "activePlanes": {
            "seatsAvail": "asientos disponibles",
            "crew": "Tripulación",
            "passenger": "Lista de Pasajeros",
            "view": "Ver",
            "status": "Estado",
            "crewDetails": "Detalles de la Tripulación",
            "name": "Nombre",
            "role": "Rol",
            "crewErr": "No hay miembros de la tripulación registrados para este vuelo",
            "close": "Cerrar",
            "passengerDetails": "Detalles de los Pasajeros",
            "gender": "Género",
            "contact": "Contacto",
            "passengerErr": "No hay pasajeros registrados para este vuelo",
            "updateStatus": "Actualizar Estado del Vuelo",
            "onTime": "A Tiempo",
            "onFlight": "En Vuelo",
            "delay": "Retrasado",
            "complete": "Completado",
            "update": "Actualizar",
            "cancel": "Cancelar"
          },
          "contactUs": {
            "first_line": "Pongámonos en contacto",
            "second_line": "¡Estamos aquí para ayudarte!",
            "subject": "Asunto",
            "message": "Tu mensaje",
            "submit": "Enviar"
          },
          "inspection": {
            "heading": "Mobius Fly realiza inspecciones",
            "text": "en todas las aeronaves registradas en su plataforma para garantizar que operen bajo los estándares de aviación más estrictos. Esto se refleja en las evaluaciones y certificaciones otorgadas a nuestros proveedores de aeronaves, así como en la formación y los cursos completados por los pilotos. Estamos creando un modelo dentro de la comunidad basado en los principios de la aviación empresarial. Al hacerlo, promovemos el uso de prácticas operativas de alta calidad como estándar, manteniendo la seguridad y mejorando la eficiencia en cada operación."
          },
          
            "terms_conditions": {
              "title": "Términos y condiciones",
              "intro": "A los usuarios, les informamos que los siguientes Términos y Condiciones son aplicables al uso de nuestros servicios.",
              "section1": {
                "title": "Uso y restricciones",
                "description": "El acceso o uso del Portal expresa la plena adhesión sin reservas del Usuario a estos Términos y Condiciones. A través del Portal, el Usuario contratará servicios y utilizará una variedad de herramientas y medios para la realización de ciertas actividades. El Usuario solo podrá acceder y utilizar el Portal para uso personal. El uso del Portal está sujeto a las siguientes restricciones: El Usuario no podrá vender, licenciar, distribuir, reproducir, duplicar, modificar, publicar, copiar, transmitir ni crear obras derivadas de cualquier contenido o servicios en el Portal sin autorización previa."
              },
              "section2": {
                "title": "Restricciones",
                "description": "El Usuario no tiene derecho a colocar hipervínculos dentro del Portal, a utilizar los enlaces del Portal o a utilizar los servicios del Portal para fines distintos a los personales, sin autorización expresa. El Usuario no puede alterar o eliminar el contenido, avisos, marcas o cualquier otra propiedad intelectual o de propiedad que se encuentre en el Portal."
              },
              "section3": {
                "title": " Propiedad intelectual",
                "description": "Los derechos de propiedad intelectual y de propiedad industrial relativos a los Servicios, el contenido, las marcas distintivas y los nombres de dominio, así como los derechos de uso y explotación de los mismos, incluyendo la divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de los propietarios del Portal."
              },
              "section4": {
                "title": "Modificaciones a los términos",
                "description": "El Portal se reserva el derecho de modificar el contenido de estos Términos y Condiciones en cualquier momento, así como de actualizar, modificar o descontinuar los Servicios proporcionados sin previo aviso. Es responsabilidad del Usuario revisar estos términos periódicamente."
              },
             
              
                "section5": {
                  "title": "Bienes y servicios de terceros vinculados",
                  "description": "La provisión de información en el Portal o en otros sitios vinculados o conectados no implica respaldo, garantía, patrocinio ni aprobación por parte de la Compañía de dicha información, bienes y/o servicios. La disponibilidad de bienes y/o servicios ofrecidos por terceros o a través de sitios vinculados o conectados no es responsabilidad de la Compañía. Por lo tanto, la Compañía no será responsable ante ninguna autoridad de ningún tipo por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o provisión de cualquier bien y/o servicio ofrecido por terceros o a través de sitios vinculados o conectados en el Portal. En cuanto a los Servicios y Contenidos proporcionados por terceros dentro o a través de enlaces al Portal (como enlaces, banners y botones), la Compañía se limita exclusivamente, para la conveniencia del Usuario, a: (i) informar al Usuario sobre ellos, y (ii) proporcionar un medio para poner en contacto al Usuario con los proveedores o vendedores. Los productos y/o servicios comercializados dentro del Portal y/o en sitios de terceros vinculados al Portal son proporcionados por comerciantes independientes, y no se entenderá en ningún caso que son responsabilidad de la Compañía. No existe relación laboral, asociación o sociedad entre la Compañía y dichos terceros. Cualquier asesoría, declaración, información y contenido de las páginas de terceros vinculadas o dentro del Portal representan las opiniones y juicios de dichos terceros; en consecuencia, la Compañía no será responsable de ningún daño o perjuicio sufrido por el Usuario como resultado de estos."
                },
                "section6": {
                  "title": "Confidencialidad",
                  "description": "La Compañía se compromete a mantener la confidencialidad de la información recibida del Usuario que tenga tal carácter de conformidad con las disposiciones legales aplicables en los Estados Unidos Mexicanos; la Compañía no asume ninguna obligación de mantener confidencial cualquier otra información proporcionada por el Usuario."
                },
                "section7": {
                  "title": "Uso de información no confidencial",
                  "description": "Al utilizar el Portal, el Usuario autoriza a la Compañía, de manera enunciativa pero no limitativa, a utilizar, publicar, reproducir, divulgar, comunicar públicamente y transmitir información no confidencial, en los términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, la Ley Federal del Derecho de Autor, la Ley Federal de Protección al Consumidor y cualquier otra legislación mexicana aplicable."
                },
                "section8": {
                  "title": "Cookies",
                  "description": "El Usuario que accede al Portal acepta recibir archivos transmitidos a ellos por los servidores de la Compañía. Una 'Cookie' es un archivo de datos que se almacena en el disco duro de la computadora del Usuario cuando accede al Portal. Dichos archivos pueden contener información como la identificación proporcionada por el Usuario o información para rastrear las páginas visitadas por el Usuario. Una Cookie no puede leer datos o información del disco duro del Usuario ni leer Cookies creadas por otros sitios o páginas. En general, las Cookies se aceptan automáticamente; el Usuario puede cambiar la configuración de su navegador en cualquier momento. Si el Usuario decide rechazar las Cookies, es posible que ciertas secciones del Portal no funcionen de manera óptima o que no funcionen en absoluto."
                },
                "section9": {
                  "title": "Aviso de privacidad de datos personales",
                  "description": "Toda la información que la Compañía recaba del Usuario es tratada con absoluta confidencialidad de acuerdo con las disposiciones legales aplicables en la legislación mexicana. Para conocer más sobre la protección de sus datos personales, consulte nuestro Aviso de Privacidad."
                },
                "section10": {
                  "title": "Claves de acceso",
                  "description": "En todo momento, el Usuario es única y definitivamente responsable de mantener la confidencialidad de las claves de acceso que pueda tener y con las que acceda a ciertos Servicios y Contenidos del Portal."
                },
                "section11": {
                  "title": "Cuentas",
                  "description": "Al crear una cuenta en el Portal, el Usuario declara, bajo protesta de decir verdad, que tiene al menos 18 años de edad o la mayoría de edad legal en su jurisdicción; el Usuario reconoce que es responsable de cualquier actividad realizada con o a través de dicha cuenta, y reconoce que está al tanto y acepta las condiciones establecidas en estos Términos y Condiciones y en el Aviso de Privacidad."
                },
                "section12": {
                  "title": "Modificaciones",
                  "description": "La Compañía se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento, sin previo aviso y/o sin el consentimiento del Usuario. En consecuencia, el Usuario debe leer detenidamente los Términos y Condiciones cada vez que tenga la intención de utilizar el Portal. Ciertos Servicios y Contenidos ofrecidos a los Usuarios en y/o a través del Portal están sujetos a condiciones específicas que sustituyen, complementan y/o modifican estos Términos y Condiciones. Por lo tanto, el Usuario debe leer cuidadosamente las condiciones específicas correspondientes antes de acceder a cualquiera de los Servicios y Contenidos."
                },
                "section13": {
                  "title": "Leyes aplicables y jurisdicción",
                  "description": "Para la interpretación, cumplimiento y ejecución de estos Términos y Condiciones, el Usuario acuerda que se aplicarán las leyes federales de los Estados Unidos Mexicanos, y los tribunales competentes de San Pedro Garza García, Nuevo León, tendrán jurisdicción, renunciando expresamente a cualquier otra jurisdicción que pueda corresponderle por razón de su domicilio presente o futuro o por cualquier otra razón."
                },
                "section14": {
                  "title": "Contacto",
                  "description": "El Usuario puede ponerse en contacto con el personal de la Compañía en cualquier momento para cualquier aclaración, comentario, pregunta y/o sugerencia relacionada con los Servicios y Contenidos, el Portal y/o estos Términos y Condiciones en contacto@mobiusfly.com."
                }
              
              
            }
          
          























        }


      }
    }
  });

export default i18n;
