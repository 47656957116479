import React, { useState } from 'react';
import axios from 'axios';
import SetPassword from './setPassword';
import { toast } from 'react-toastify';
import Loading from '../Loader/Loading';
import { useTranslation } from 'react-i18next';
import '../../i18n'

function SeguridadClient() {
   const {t}=useTranslation()
   const [showChangeForm, setShowChangeForm] = useState(false);
   const [oldPassword, setOldPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [loading, setLoading] = useState(false);


   const token = localStorage.getItem('ACCESS_TOKEN');
   const clearInputs=()=>{
      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
   }

   const setPassword = () => {
     
      const url = 'api/accounts/password_change/';
      const headers = {
         Authorization: `Bearer ${token}`
      };

      if (newPassword !== confirmPassword) {
         toast.error('New password and confirm password do not match.', {
            position: 'bottom-right',
            autoClose: 2000,
         });
         console.error("New password and confirm password do not match.");
         return;
      }
      if (!oldPassword) {
         toast.error('Your Old password is required', {
            position: 'bottom-right',
            autoClose: 2000,
         });
      
         return;
      }
      if (!newPassword) {
         toast.error('New password is required', {
            position: 'bottom-right',
            autoClose: 2000,
         });
      
         return;
      }

      setLoading(true)


      const passwordChangeData = {
         old_password: oldPassword,
         new_password: newPassword
      };

      axios.post(url, passwordChangeData, { headers })
         .then(response => {
            console.log("Password changed successfully.");
           
            toast.success('Password changed successfully.', {
               position: 'bottom-right',
               autoClose: 2000,
            });
            clearInputs()
            setLoading(false)
            setShowChangeForm(false); 
         })
         .catch(error => {
            toast.error('There was an error updating password please make sure you old password is correct!', {
               position: 'bottom-right',
               autoClose: 2000,
            });
            console.error("Error:", error.response ? error.response.data : error.message);
         });
   };

   const goBack=()=>{
      setShowChangeForm(false); 
   }

   return (
      <div className="h-full w-full flex flex-col gap-5 text-black">
         {showChangeForm ? (
                <div>
                  {loading?(
                     
                   <Loading/>
                     
                  ):(
     
            <div className=' w-full'>
                <SetPassword
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                setPassword={setPassword}
                goBack={goBack}
                />
          
            </div>
            )}
            </div>
         ) : (
            <>
               <div className='flex flex-col gap-2'>
                  <div className='text-lg font-bold'>{t('security.pass')}</div>
                  <div className='flex gap-5'>
                     <span>● ● ● ● ● ● ● ● ●</span>
                     <button
                        onClick={() => setShowChangeForm(true)}
                        className='text-mobius-golden'>
                       {t('security.change')}
                     </button>
                  </div>
               </div>
               <div className='flex flex-col gap-2'>
                  <div className='text-lg font-bold'> {t('security.twoStep')}</div>
                  <div className='flex gap-5'>
                     <span>
                     {t('security.twoStepDesc')}
                     </span>
                     <button className='text-mobius-golden'> {t('security.add')}</button>
                  </div>
               </div>
            </>
         )}
      </div>
   );
}

export default SeguridadClient;
