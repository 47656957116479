import React, { useState, useEffect } from "react";
import '../../i18n';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../Loader/Loading";
import { ArrowLeft } from "lucide-react";
const CrewDetails = () => {
    const { t } = useTranslation();
    const token = localStorage.getItem('ACCESS_TOKEN');
    const [crewName, setCrewName] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const [crewLastName, setCrewLastName] = useState('');
    const [crewLicense, setCrewLicense] = useState('');
    const [crewRole, setCrewRole] = useState('First Officer');
    const [crewData, setCrewData] = useState([]);
    const [isViewingCrew, setIsViewingCrew] = useState(false); // Track whether we're viewing the crew list or the registration form

    // Register new crew member
    const handleCrewData = async () => {
        if (!crewName || !crewRole || !crewLicense) {
            toast.error('Please fill in all required fields.', {
                position: 'bottom-right',
                autoClose: 2500,
            });
            return;
        }

        setIsloading(true);
        axios
            .post(`/api/crew/crew/`, {
                name: crewName,
                surname: crewLastName,
                role: crewRole,
                licenseNumber: crewLicense,
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => {
                toast.success('Crew member registered successfully!', {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
                setCrewName('');
                setCrewLastName('');
                setCrewLicense('');
                setCrewRole('First Officer');
                setIsloading(false);
            })
            .catch((error) => {
                console.error('Error registering crew', error.response ? error.response.data : error.message);
                setIsloading(false);
            });
    };

    useEffect(() => {
        // Fetch the crew data
        const fetchCrewData = async () => {
            try {
                const response = await axios.get('api/crew/crew/', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCrewData(response.data);
            } catch (error) {
                console.error('Error fetching crew data!', error);
            }
        };

        // Fetch data and set up polling every 4 seconds
        if (token) {
            fetchCrewData();
            const intervalId = setInterval(() => fetchCrewData(), 4000);
            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [token]);

    // Delete a crew member
    const handleDeleteCrew = async (id) => {
        try {
            await axios.delete(`/api/crew/crew/${id}/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCrewData(crewData.filter(crew => crew.id !== id));
            toast.success('Crew member deleted successfully.', {
                position: 'bottom-right',
                autoClose: 2000,
            });
        } catch (error) {
            toast.error('Failed to delete crew member.', {
                position: 'bottom-right',
                autoClose: 2000,
            });
        }
    };

    return (
        <div className="w-full">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {isViewingCrew ? (
                       <div className="w-[90%] overflow-y-auto no-scrollbar h-[70vh] m-auto mt-4">
                       <div className="flex justify-between items-center mb-4">
                           <h2 className="md:text-3xl text-2xl font-bold">{t('crewDetails.registeredMembers')}</h2>
                           <button
                               onClick={() => setIsViewingCrew(false)}
                               className="text-mobius-golden hover:underline"
                           >
                            <ArrowLeft/>
                              
                           </button>
                       </div>
                   
                       {crewData.length > 0 ? (
                           <table className="table-auto w-full bg-gray-100 rounded-md shadow-md">
                               <thead>
                                   <tr className="bg-gray-200">
                                       <th className="text-left p-2 font-bold">{t('crewDetails.name')}</th>
                                       <th className="text-left p-2 font-bold">{t('crewSelection.role')}</th>
                                       <th className="text-left p-2 text-center font-bold">{t('crewSelection.licenseNo')}</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {crewData.map((crew) => (
                                       <tr key={crew.id} className="border-b">
                                           <td className="p-2">
                                               <p className="font-bold">{crew.name} {crew.surname}</p>
                                           </td>
                                           <td className="p-2 text-gray-600">
                                               {crew.role}
                                           </td>
                                           <td className="p-2 text-center text-gray-600">
                                               {crew.licenseNumber}
                                           </td>
                                           {/* <td className="p-2">
                                               <button
                                                   onClick={() => handleDeleteCrew(crew.id)}
                                                   className="text-sm font-bold text-red-500 hover:text-red-700"
                                               >
                                                   {t('crewSelection.delete')}
                                               </button>
                                           </td> */}
                                       </tr>
                                   ))}
                               </tbody>
                           </table>
                       ) : (
                           <p>{t('crewDetails.noMembers')}</p>
                       )}
                   </div>
                   
                    ) : (
                        <div>
                            <div className="md:text-3xl text-3xl mt-4 font-bold text-center">
                                {t('crewDetails.introText')} {t('crewDetails.member')}
                            </div>
                            <div className="mt-8 flex flex-col justify-center items-center gap-10">
                                <div className="flex gap-10">
                                    {/* Crew Name Input */}
                                    <div className="flex flex-col items-center">
                                        <label className="text-xl font-bold">
                                            {t('crewDetails.name')}
                                        </label>
                                        <input
                                            type="text"
                                            value={crewName}
                                            onChange={(e) => setCrewName(e.target.value)}
                                            placeholder={t('crewDetails.nameEnter')}
                                            className="md:w-[200px] w-[200px] h-[40px] rounded-lg bg-mobuis-gray px-5"
                                        />
                                    </div>
                                    {/* Crew Last Name Input */}
                                    <div className="flex flex-col items-center">
                                        <label className="text-xl font-bold">
                                            {t('crewDetails.lName')}
                                        </label>
                                        <input
                                            type="text"
                                            value={crewLastName}
                                            onChange={(e) => setCrewLastName(e.target.value)}
                                            placeholder={t('crewDetails.lNameEnter')}
                                            className="md:w-[200px] w-[200px] h-[40px] rounded-lg bg-mobuis-gray px-5"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-10">
                                    {/* Crew License Input */}
                                    <div className="flex flex-col items-center">
                                        <label className="text-xl font-bold">
                                            {t('crewDetails.license')}
                                        </label>
                                        <input
                                            type="text"
                                            value={crewLicense}
                                            onChange={(e) => setCrewLicense(e.target.value)}
                                            placeholder={t('crewDetails.licenseEnter')}
                                            className="md:w-[200px] w-[200px] h-[40px] rounded-lg bg-mobuis-gray px-5"
                                        />
                                    </div>
                                    {/* Crew Role Select */}
                                    <div className="flex flex-col items-center">
                                        <label className="text-xl font-bold">
                                            {t('crewDetails.role')}
                                        </label>
                                        <select
                                            value={crewRole}
                                            onChange={(e) => setCrewRole(e.target.value)}
                                            className="md:w-[200px] w-[200px] h-[40px] rounded-lg bg-mobuis-gray px-5"
                                        >
                                            <option value="First Officer">{t('crewDetails.role1')}</option>
                                            <option value="Captain">{t('crewDetails.role2')}</option>
                                        </select>
                                    </div>
                                </div>
                                {/* Submit Button */}
                                <button
                                    onClick={handleCrewData}
                                    className="outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden border-2 border-mobius-golden hover:text-white transition-colors duration-150 h-10 px-6 rounded-xl"
                                >
                                    {t('crewSelection.reg')}
                                </button>
                                {/* Check Registered Members Link */}
                                <div
                                    className="text-mobius-golden cursor-pointer hover:underline"
                                    onClick={() => setIsViewingCrew(true)}
                                >
                                    {t('crewDetails.checkMembers')}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CrewDetails;
