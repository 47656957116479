import React from 'react';
import { LogoTransp } from '../assets';
import { Globe } from 'lucide-react';
import { NavLink } from 'react-router-dom';

// import { GlobeAltIcon } from '@heroicons/react/24/solid';
import MenuUserMobile from './menu-user-mobile';

const NavbarDesktop = () => {
   return (
      <div className="w-full h-16 flex px-2 bg-black justify-between md:hidden py-2 items-center fixed z-50">
          <NavLink
            to="/"
           
          >
            <img src={LogoTransp} alt="Logo" className="w-16" />
          </NavLink>
         <div className="flex gap-4 font-medium text-base items-center justify-center">
            <div className="flex space-x-4 items-center">
               <Globe size={20} color="white" />
               <MenuUserMobile />
            </div>
         </div>
      </div>
   );
};

export default NavbarDesktop;
