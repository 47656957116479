import React from "react";
import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import '../../i18n'
const TripComponent =({departure,
    origin,
    arrival,
    dataId,
    destination,
    originCity,
    destinationCity,
    flightStatus,
    seatNo,
    flightNo,
    Date,
    isSmall,
    showDetails,
    airline
    
    
    })=>{
        const {t}=useTranslation()
        const [flightTime, setFlightTime] = useState('');
        const [status, setStatus] = useState(flightStatus);

const openDetails=()=>{
  showDetails(dataId)
}
        const getStatusColor = () => {
            switch (status) {
              case 'On Time':
                return 'text-green-600';
              case 'On Flight':
                return 'text-blue-600';
              case 'Delayed':
                return 'text-yellow-600';
              case 'Completed':
                return 'text-gray-600';
              default:
                return 'text-black';
            }
          };
  

        useEffect(() => {
            if (departure && arrival) {
              const [hours1, minutes1] = departure.split(':').map(Number);
              const [hours2, minutes2] = arrival.split(':').map(Number);
        
              const totalMinutes1 = hours1 * 60 + minutes1;
              const totalMinutes2 = hours2 * 60 + minutes2;
        
              const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);
              const diffHours = Math.floor(differenceInMinutes / 60);
              const diffMinutes = differenceInMinutes % 60;
              const calculatedTime = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
              setFlightTime(calculatedTime);
            }
          }, [departure, arrival]);
    return(
        <div className='flex mb-8 flex-col'>
      <div className='flex gap-2 flex-col'>
        <div className='text-2xl font-bold text-center'>{originCity} {t('myFlights.to')} {destinationCity} {t('myFlights.city')} </div>
        <div className='text-center text-sm text-black'>{Date}</div>
      </div>

      <div className='flex items-center md:flex-row flex-col  border-2 border-mobius-golden rounded-lg md:gap-6 gap-4 py-8 mt-4 rounded-md px-4 width-full px-6 md:justify-between justify-center'>
        <div className='text-center'>
          <p className='text-lg text-black font-bold'>{departure} </p>
          <p className='text-md text-gray-500'>{origin}</p>
      

          
        </div>

        <div className='flex relative top-4 gap-[4px] flex-col items-center'>
          <div className={`flex  ${isSmall? 'md:w-[150px]'  :'md:w-[250px]' }   w-[150px] justify-center items-center relative`}>
            <div className='p-2 bg-mobius-golden rounded-full absolute left-0'></div>
            <div className={`w-[150px] ${isSmall? 'md:w-[150px]'  :'md:w-[250px]' } bg-mobius-golden rounded-lg h-1`}></div>
            <div className='p-2 bg-mobius-golden rounded-full absolute right-0'></div>
          </div>
          <p className='text-gray-500 text-sm font-bold'>
            {flightTime}h
            
            </p>
            <p className='text-md text-gray-500'>{airline} </p>
          
        </div>

        <div className='text-center flex flex-col md:items-start items-center  md:justify-start justify-center'>
          <p className='text-lg text-black font-bold'>{arrival}</p>
          <p className='text-md text-gray-500'>{destination} </p>
        </div>

        
      

        <div className='text-center flex flex-col items-center justify-start'>
          <p className={`md:mb-4 mb-1 ${isSmall?'text-md':'text-lg'}  font-bold`}>{t('myFlights.fNumber')}</p>
          <p className={`text-md font-bold text-gray-500 text-center`}>{flightNo} </p>
        </div>
        <div className='text-center flex flex-col items-center justify-start'>
          <p className={`md:mb-4 mb-1 ${isSmall?'text-md':'text-lg'}    font-bold`}>{t('myFlights.sNumber')}</p>
          <p className={`text-md font-bold text-gray-500 text-center`}>{seatNo} </p>
        </div>

        <div className='text-center flex flex-col md:items-start items-center  md:justify-start justify-center'>
          <p className={`md:mb-4 mb-1 ${isSmall?'text-md':'text-lg'}   font-bold`}>{t('myFlights.status')}</p>
          <p className={`text-md ${getStatusColor()} text-nowrap font-bold text-gray-500`}>{status} </p>
        </div>


        <div className='text-center flex flex-col items-start justify-start' onClick={openDetails}>
          <p className={`${isSmall?'text-md':'text-lg'} mb-4 cursor-pointer transform transition-transform duration-300 hover:scale-110 hover:text-mobius-golden font-bold`}>{t('myFlights.details')}</p>

        </div>
      </div>

 
 
   
     
    </div>

    )
}
export default TripComponent