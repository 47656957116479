import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../i18n'
const DetailsInput=({first_heading,first_desc,second_heading,second_desc,value_1,onChange_1,value_2,onChange_2,type_1,type_2})=>{
   const {t}=useTranslation()
    return(
        <div className='flex  justify-center flex-col md:flex-row  md:gap-16 gap-16 my-[8%] mt-[9%] my-4 items-center'>
        <div className='flex flex-col'>
           <div className="flex flex-col justify-center gap-2">
              <div className="text-2xl text-center font-bold">{first_heading}</div>
              <div className="text-xl text-center mt-2 ">
                 {first_desc}
              </div>
           </div>
           <div className="flex md:gap-10 justify-center items-center  w-full">
              <input
                 type={type_1}
                 value={value_1}
                 
                 placeholder={t('flightDetails.flightNumber')}
                 onChange={(e) => onChange_1(e.target.value)}
                 className="  px-2  h-[35px] mt-2   text-[1rem] border-2 border-gary-300 outline-mobius-golden   rounded-md "
              />
           </div>
        </div>

        <div className='flex flex-col'>
           <div className="flex flex-col justify-center gap-2">
              <div className="text-2xl text-center font-bold">{second_heading}</div>
              <div className="text-xl text-center mt-2 ">
                 {second_desc}
              </div>
           </div>
           <div className="flex md:gap-10 justify-center items-center  w-full">
              <input
                 type={type_2}
                 value={value_2}
                 placeholder={t('flightDetails.airline')}
                 onChange={(e) => onChange_2(e.target.value)}
                 className="  px-2  h-[35px] mt-2   text-[1rem] border-2 border-gary-300 outline-mobius-golden   rounded-md "
              />
           </div>
        </div>
     </div>

    )
}
export default DetailsInput