import React, { useEffect, useRef } from 'react';
import { motion, useAnimate } from 'framer-motion';
import { mountainsG, takeOffJet } from '../assets';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n'

export default function Hero() {
   // let location = useLocation();
   const ref = useRef(null);
   const { t } = useTranslation()
   const mountains = mountainsG;
   const jet1 = takeOffJet;

   const [scope, animate] = useAnimate();

   const [scopeImage, animateImage] = useAnimate();

   const [scopeJet, animateJet] = useAnimate();

   const [scopeText, animateText] = useAnimate();

   async function handleText() {
      if (!scope.current) return;
      await animate(scope.current, { opacity: 1, scale: 1.5 });
      // Here wait for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 1500));
      if (!scope.current) return;
      await animate(scope.current, { y: -250, scale: 1 });
      await new Promise((resolve) => setTimeout(resolve, 3500));
      if (!scope.current) return;
      animate(scope.current, { y: 250, scale: 0, opacity: 0, display: 'none' });
   }
   async function handleImage() {
      if (!scopeImage.current) return;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (!scopeImage.current) return;
      await animateImage(scopeImage.current, { opacity: 1, scale: 1 });
   }

   async function handleJet() {
      if (!scopeJet.current) return;
      await new Promise((resolve) => setTimeout(resolve, 2200));
      if (!scopeJet.current) return;
      await animateJet(scopeJet.current, { opacity: 1, scale: 1.4 });
      animateJet(
         scopeJet.current,
         {
            x: [7, -7, 7], // {from, to, from
            rotate: [0, -0.5, 1, -0.5, 0], // {from, to, from}
         },
         {
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
            duration: 5,
         },
      );
   }

   async function handleText2() {
      if (!scopeText.current) return;
      await new Promise((resolve) => setTimeout(resolve, 6500));
      if (!scopeText.current) return;
      await animateText(scopeText.current, {
         y: -200,
         scale: 1,
         opacity: 1,
         display: 'block',
      });
   }

   useEffect(() => {
      handleText();
      handleText2();
      handleImage();
      handleJet();
   });

   return (
      <div
         className="w-screen min-h-[calc(100vh-64px)] md:flex flex-col p-10 pt-0 mt-[1rem] relative text-black justify-center items-center hidden"
         ref={ref}
      >
         <div
            className="text-4xl font-[800] mb-4 text-center"
           
            
         >
            {t('heroHome.upperText')}
         </div>
         <div
            className="text-4xl font-[800]  text-center"
          
         >
            {t('heroHome.lowerText')}
         </div>
         <div className="flex items-center justify-center ">
            <motion.div
               ref={scopeImage}
               className="rounded-full "
               initial={{ opacity: 0, scale: 0.5, y: 50 }}
               transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
               }}
            >
               <img src={mountains} className="bg-cover w-[850px] h-[300px] rounded-[80%]" alt="" />
            </motion.div>
         </div>
         <motion.img
            ref={scopeJet}
            src={jet1}
            className="absolute w-[35%] mb-[1rem]"
            initial={{ opacity: 0, scale: 0.5, y: 35 }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         />
         {/* <motion.div
            className="text-6xl font-bold text-center"
            ref={scopeText}
            initial={{ opacity: 0, scale: 0.5, display: 'none' }}
            transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01],
            }}
         >
            {t('heroHome.lowerText')}
         </motion.div> */}
      </div>
   );
}
