import React from 'react';
import  LogoTransp  from '../../components/assets/LogoTransp.png';
import { NavLink } from 'react-router-dom';
import { Globe } from 'lucide-react';
// import { GlobeAltIcon } from '@heroicons/react/24/solid';
import MenuUser from '../../components/Navbar/menu-user';
import { useAuth } from '@/components';
import DatePicker from 'react-datepicker';
import CityDropdown from './CityDropdown';
import { ArrowLeftRight } from 'lucide-react';
import { Minus } from 'lucide-react';
import { Menu } from '@headlessui/react';
import Flag from 'react-world-flags'; 
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import '../../i18n'

const NavbarMobile = ({startDate,setStartDate,endDate,setEndDate,setOrigin,setDestination,destination,origin}) => {
   const { isLoggedIn } = useAuth();
  
   const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en'; // Get current language

  // Handle language change
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Persist language selection
  };

   return (
      <div className="w-full h-20 hidden px-12 bg-black justify-between md:flex py-2 items-center">
         <div className='flex gap-8'>

         <NavLink
            to="/"
           
          >
            <img src={LogoTransp} alt="Logo" className="w-28 " />
          </NavLink>
         <div className='flex '>
                        <div className='flex flex-col my-4 w-full justify-center'>
                        
                          <div className="flex items-center justify-center p-2   my-0 ">
                            <DatePicker
                              selected={startDate}
                              dateFormat="dd MMM"
                              className='outline-none text-white bg-black text-center w-[80px]'
                              onChange={(date) => setStartDate(date)}  
                            />
                            <div>
                              <Minus size={12} className='text-white'/>
                            </div>
                            <DatePicker
                              selected={endDate}
                              dateFormat="dd MMM"
                              placeholderText='Date'
                              className='outline-none text-white bg-black text-center w-[80px]'
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                        </div>

                        <div className='flex flex-col my-4 border-l-2  w-full justify-center'>
                         
                          <div className="flex items-center justify-center p-2  my-0 rounded-md ">
                            <CityDropdown setSelectedCity={setOrigin} selectedCity={origin} />
                            <div>
                              <ArrowLeftRight size={18} className='text-white'/>
                            </div>
                            <CityDropdown setSelectedCity={setDestination} selectedCity={destination} />
                          </div>
                        </div>

                       
                      </div>

        
         </div>
         <div className="flex gap-6 font-medium text-base items-center justify-center">
            <div className="flex gap-8 items-center">
             

                      <NavLink
                  to="/"
                  className="aria-[current=page]:text-mobius-golden text-white"
               >
           <span className='normal-case'>{t('Navbar.home')}</span> 
               </NavLink>
                 
            
           
           
              
               <Menu as="div" className="relative">
            <Menu.Button className="flex items-center focus:outline-none">
              <Globe className='cursor-pointer' size={20} color="white" />
            </Menu.Button>
            
            <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => changeLanguage('en')}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } flex justify-between w-full px-4 py-2 text-sm text-gray-700`}
                    >
                      <div className="flex justify-between  gap-6  items-center">
                      <div className="flex  items-center">
                        <Flag code="GB" className="mr-2" width="20" /> English
                        </div>
                       
                        {currentLang === 'en' && <Check size={18}/>}
                      </div>
                     
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => changeLanguage('sp')}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } flex justify-between w-full px-4 py-2 text-sm text-gray-700`}
                    >
                      <div className="flex justify-between gap-6 items-center">
                      <div className="flex items-center">
                        <Flag code="ES" className="mr-2" width="20" /> Spanish
                        </div>
                       
                        {currentLang === 'sp' && <Check size={18}/>}
                      </div>
                   
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
            </div>
            {isLoggedIn ? (
               <div className="flex gap-8 items-center bg-mobius-golden rounded-lg">
                  <MenuUser />
               </div>
            ) : (
               <div className="flex pl-4 gap-8 border-l-2 border-mobius-golden items-center">
                  <NavLink
                     to="/login"
                     className="text-white aria-[current=page]:text-mobius-golden"
                  >
               {t('Navbar.login')}
                  </NavLink>
                  <NavLink
                     to="/register"
                     className="text-white peer aria-[current=page]:text-mobius-golden p-2 px-4 bg-mobius-golden rounded-lg aria-[current=page]:bg-mobius-golden/50"
                  >
                      {t('Navbar.reg')}
                  </NavLink>
               </div>
            )}
         </div>
      </div>
   );
};

export default NavbarMobile;
