import React, { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import '../../i18n'

const Inputs = ({ label, value, setValue, type }) => {
  return (
    <div className="flex flex-col w-full space-y-1">
      <label htmlFor={label} className="text-lg">
        {label}
      </label>
      <input
        type={type}
        className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

const GenderSelect = ({ gender, setGender }) => {
  const {t}=useTranslation()
  return (
    <div className="flex flex-col w-full space-y-1">
      <label htmlFor="gender" className="text-lg">
        {t('bookFlight.gender')}
      </label>
      <select
        className="w-full py-1 px-2 text-base rounded-full bg-white/60 border-2 border-mobius-dark-gray outline-none"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
      >
        <option value=""> {t('bookFlight.genderSelect')}</option>
        <option value="Masculino">
        {t('bookFlight.male')}
          </option>
        <option value="Femenino">
        {t('bookFlight.female')}
        </option>
        <option value="other">
        {t('bookFlight.other')}

        </option>
      </select>
    </div>
  );
};

const InputCard = ({ next, passengers, index, updatePassengerData, passengerData, finalize }) => {
  const {t}=useTranslation()
  const [first_name, setFirstName] = useState(passengerData.first_name || '');
  const [last_name, setLastName] = useState(passengerData.last_name || '');
  const [email, setEmail] = useState(passengerData.email || '');
  const [gender, setGender] = useState(passengerData.gender || '');
  const [birth_date, setDateOfBirth] = useState(passengerData.birth_date || '');
  const [phone, setPhone] = useState(passengerData.phone || '');

  const validateInputs = () => {
    if (!first_name || !last_name || !email || !gender || !birth_date || !phone) {
      toast.error(t('bookFlight.fieldsError'), {
        position: 'bottom-right',
        autoClose: 2000,
      });
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error(t('bookFlight.emailError'), {
        position: 'bottom-right',
        autoClose: 2000,
      });
      return false;
    }

    return true;
  };

  const savePassengers=()=>{
updatePassengerData(index, { first_name, last_name, email, gender, birth_date, phone });
  }
  useEffect(()=>{
    savePassengers()

  })
  const handleFinalize = async() => {
    if (validateInputs()) {
     
      finalize();
    }
  };
  // useEffect(()=>{
  //   console.log(passengers,'passengers in input card ')
  // })

  const handleNext =async () => {
    if (validateInputs()) {
    
      next();
    }
  };

  return (
    <div className="p-6 h-[100vh] w-full space-y-6">
      {/* First Row */}
      <div className="flex md:flex-row flex-col justify-between gap-4">
        <div className="md:w-1/4 w-full">
          <Inputs label={t('bookFlight.fName')} value={first_name} type="text" setValue={setFirstName} />
        </div>
        <div className="md:w-1/4 w-full">
          <Inputs label={t('bookFlight.lName')} value={last_name} type="text" setValue={setLastName} />
        </div>
        <div className="md:w-1/4 w-full">
          <Inputs label={t('bookFlight.email')} type="email" value={email} setValue={setEmail} />
        </div>
      </div>

      {/* Second Row */}
      <div className="flex md:flex-row flex-col gap-4 justify-between">
        <div className="md:w-1/4 w-full">
          <Inputs label={t('bookFlight.phone')} type="text" value={phone} setValue={setPhone} />
        </div>
        <div className="flex flex-col md:w-1/4 w-full space-y-1">
          <Inputs label={t('bookFlight.dob')} type="date" value={birth_date} setValue={setDateOfBirth} />
        </div>
        <div className="flex flex-col md:block hidden md:w-1/4 w-full space-y-1">
        </div>
      </div>

      {/* Third Row */}
      <div className="flex justify-between md:flex-row flex-col items-end gap-4">
        <div className="md:w-1/4 w-full">
          <GenderSelect gender={gender} setGender={setGender} />
        </div>
        {(index + 1) >= passengers ? (
          <div
            className="text-xl bg-white text-black hover:text-white hover:bg-mobius-golden p-2 border-2 border-mobius-golden cursor-pointer mr-6 rounded-md"
            onClick={handleFinalize}
          >
           {t('bookFlight.finish')}
          </div>
        ) : (
          <button
            onClick={handleNext}
            className="text-xl font-bold bg-white text-black hover:text-white hover:bg-mobius-golden border-2 border-mobius-golden px-2 py-1 mr-6 rounded-full"
          >
            →
          </button>
        )}
      </div>
    </div>
  );
};

export default InputCard;
