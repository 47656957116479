import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'

export default function SettingsClient() {
   const {t}=useTranslation()
   return (
      <div className="h-full w-full flex flex-col gap-5 text-black">
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">{t('settings.language')}</div>
            <div className="flex gap-5">
               <span>{t('settings.sp')} </span>
               <button className="text-mobius-golden">{t('settings.change')}</button>
            </div>
         </div>
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">{t('settings.currency')}</div>
            <div className="flex gap-5">
               <span>{t('settings.peso')} </span>
               <button className="text-mobius-golden">{t('settings.change')}</button>
            </div>
         </div>
         <div className="flex flex-col gap-2">
            <div className="text-lg font-bold">{t('settings.timeZone')}</div>
            <div className="flex gap-5">
               <span>
                  CT
               </span>
               <button className="text-mobius-golden">{t('settings.change')}</button>
            </div>
         </div>
      </div>
   );
}
