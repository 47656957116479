import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
import aboutUs1 from '../../components/assets/aboutUs1.png'
import aboutUs2 from '../../components/assets/aboutUs2.png'
function AboutUsPage() {
   const {t}=useTranslation()
   return (
      <div>
         <div className="w-screen h-[60vh] aboutus flex justify-center items-center">
            <div className="w-[80%] text-center md:text-4xl text-xl md:leading-loose leading-relaxed">
              {t('AboutUs.intro')}
               <strong>  {t('AboutUs.introS')}</strong>
            </div>
         </div>
         <div className="w-screen h-screen flex flex-col">
            <div className="flex md:flex-row flex-col w-full h-1/2">
               <div className="md:text-2xl text-lg text-center leading-relaxed md:w-1/2 w-full md:h-full h-1/2 flex justify-center items-center md:p-20 p-5 bg-mobuis-gray">
               {t('AboutUs.feature1')}
               </div>
               
                  <img src={aboutUs1} className="w-full  md:block  md:w-1/2 md:h-full h-1/2   " alt="aboutUs" />
            
            </div>
            <div className="flex md:flex-row flex-col-reverse w-full h-1/2 ">
            <img src={aboutUs2} className="w-full  md:block  md:w-1/2 md:h-full h-1/2   " alt="aboutUs" />
               <div className="md:text-2xl text-lg text-center leading-relaxed md:w-1/2 w-full md:h-full h-1/2 flex justify-center items-center md:p-20 p-5 bg-mobuis-gray">
               {t('AboutUs.feature2')}
               </div>
            </div>
            {/* <div className="md:hidden w-full h-1/2 flex flex-col">
               <div className="text-lg text-center leading-relaxed w-full h-1/2 flex justify-center items-center md:p-20 p-5 bg-mobuis-gray">
                  Disfruta de la comodidad y exclusividad de nuestros jets
                  privados, equipados con las últimas comodidades y tecnología.
               </div>
               <div className="w-full h-1/2 text-xl text-center p-20 bg-gray-500 flex justify-center items-center">
                  img placeholder
               </div>
            </div> */}
         </div>
         <div className="w-screen h-screen bg-black flex flex-col justify-evenly items-center md:p-20 p-5 text-center">
            <div className='md:text-7xl text-5xl font-bold text-white'> {t('AboutUs.joinText')}</div>
            <div className='bg-mobius-golden w-full py-0.5 rounded-lg'></div>
            <div className='md:text-4xl text-xl text-white/80'> {t('AboutUs.discoverText')}</div>
         </div>
         <div className='w-screen  text-center md:p-20 p-5 py-8 flex justify-center  items-center aboutusbg2'>
            <div className='md:text-4xl text-lg leading-relaxed'>
               {t('AboutUs.commitedText')}
               </div>
         </div>


         <div className='bg-gray-200 flex md:flex-row flex-col   md:p-12 p-8 md:py-[7rem]'>
            <div className='md:border-r-2 text-center md:text-left md:border-b-0 border-mobius-golden border-r-0 border-b-2 text-lg md:px-[9rem] px-6 py-8 md:py-0'>
            &ensp;  {t('AboutUs.reduceText')} 
            </div>
            <div className=' text-center md:text-left  md:px-[9rem] px-6 py-8 md:py-0   text-lg'>
            {t('AboutUs.optimizeText')}             </div>
         </div>

         <div className='flex flex-col md:w-[85%] w-full mx-auto my-16  md:p-8 md:px-14 px-8 gap-8'>
            <div className='text-4xl font-bold text-center'>
            {t('AboutUs.contact')}
            </div>

            <div className='text-lg mt-6'>
            {t('AboutUs.contactDesc')}            </div>
            <div  className='text-lg'>
            contacto@mobiusfly.com
            </div>
            <div className='text-lg'>
           <span className='font-bold text-lg'>{t('AboutUs.openingText')}:  </span> {t('AboutUs.hours')}
            </div>
            <div className='text-lg'>
            {t('AboutUs.availableText')}            </div>


         </div>
      </div>
   );
}

export default AboutUsPage;
