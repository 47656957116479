import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SummaryComponent from "./SummaryComponent";
import PriceBreakdown from "./PriceBreakdown";
import PlaneComponent from "./PlaneComponent";
import { useTranslation } from "react-i18next";
import '../../i18n'
import { useEffect } from "react";

const OrderSummary = () => {
    const {t}=useTranslation()
    const location = useLocation();
    const  { isRound } = location.state || {};

    
    useEffect(()=>{
        console.log("is Round in order summary is ",isRound)
    })
    // Example usage
    return (
        <div className="">
            <div className="text-2xl px-[5rem]  font-bold my-6 mt-[5rem]">
               {t('orderSummary.summaryText')}
            </div>
            <div className="flex justify-evenly items-start md:px-10 px-2 pb-10">
                <div className=" pb-[8rem]">
                <SummaryComponent isRound={isRound}
                
                        
                
                
                />

          
                </div>

                
               
            </div>
        </div>
    )
}
export default OrderSummary