import React from 'react'
import { useTranslation } from 'react-i18next';
import '../../i18n'
const PlaneDetailsSelection =({year,model,setYear,setModel,tailNumber,setTailNumber})=>{
   const {t}=useTranslation()
  return(
    <div>
<div className=" md:h-[70vh] h-[100vh] md:mt-16 mt-4  flex md:flex-row flex-col md:justify-center justify-evenly items-center gap-10">
               <div className="flex flex-col justify-start md:gap-14 md:h-1/2 items-center">
                  <div className="flex flex-col md:gap-5 gap-2 items-center text-center">
                     <div className=" text-3xl font-bold">
                        {t('planeDetails.year')}
                     </div>
                     <div className="md:text-xl md:w-[250px] w-[200px]">
                     {t('planeDetails.yearDesc')}
                     </div>
                  </div>

                  <input
                  type="date"
                  className="w-full py-1 rounded-lg px-2 text-base  bg-mobuis-gray border-2 outline-none"
                  value={year}
                 
                  onChange={(e) => setYear(e.target.value)}
               />
                 
               </div>
               <div className="flex flex-col justify-start md:gap-14 md:h-1/2 items-center">
                  <div className="flex flex-col md:gap-5 gap-2 items-center text-center">
                     <div className=" text-3xl font-bold">
                     {t('planeDetails.model')}
                     </div>
                     <div className="md:text-xl md:w-[250px] w-[200px]">
                     {t('planeDetails.modelDesc')}
                     </div>
                  </div>
                  <input
                  type="text"
                  className="w-full py-1 rounded-lg px-2 text-base  bg-mobuis-gray border-2 outline-none"
                  value={model}
                  placeholder={t('planeDetails.enterText')}
                  onChange={(e) => setModel(e.target.value)}
               />
                 
               </div>
               <div className="flex flex-col justify-start md:gap-14 md:h-1/2 items-center">
                  <div className="flex flex-col gap-5 items-center text-center">
                     <div className=" text-3xl font-bold">
                     {t('planeDetails.tail')}
                     </div>
                     <div className="md:text-xl md:w-[250px] w-[200px] ">
                     {t('planeDetails.tailDesc')}
                     </div>
                  </div>
                  <input
                     type="text"
                     name="matricula"
                     id="matricula"
                     placeholder="N12345"
                     value={tailNumber}
                     onChange={(e) => setTailNumber(e.target.value)}
                     className="md:w-5/6 w-3/5 md:mt-0 mt-5 h-[40px]  rounded-lg  md:w-[250px] w-[200px] bg-mobuis-gray px-5 md:py-2"
                  />
               </div>
            
            </div>
           
    </div>
    )
}
export default PlaneDetailsSelection;