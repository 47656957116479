import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import axios from 'axios';
import {
   InformacionGeneral,
   SeguridadClient,
   BoletosClient,
   SettingsClient,
   PlanesOwner,

} from '@/components';
import EarningComponent from '@/components/Profile/EarningComponent';
import ActivePlane from '@/components/Profile/ActivePlane';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import { ShieldAlertIcon, Ticket, Settings, Plane,CircleDollarSign } from 'lucide-react';
import { useAuth } from '@/components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import '../../i18n'

const ProfilePage = () => {
   const {t}=useTranslation()
   const id = localStorage.getItem('USER_ID');
   const token = localStorage.getItem('ACCESS_TOKEN');
   const { isOwner } = useAuth();
   const [activeFlights, setActiveFlights] = useState([])
   const [userData, setUserData] = useState(null);
   useEffect(() => {
      // Replace these variables with your actual data
      const baseURL = '/api/fleet/owner-dashboard';
      // Set up headers with the authentication token
      const headers = {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json',
      };



      // Make the request to list all active flights for the owner
      axios.get(baseURL, { headers })
         .then(response => {
            setActiveFlights(response?.data)
            // console.log(response?.status); // Should be 200
            //    console.log(response?.data); // List of active flights
         })
         .catch(error => {
            console.error(error.response?.status);
            console.error(error.response?.data);
         });
   })
   useEffect(() => {
      if (id && token) {
         axios
            .get(`/api/accounts/users/${id}`, {
               headers: {
                  'Authorization': `Bearer ${token}`
               }
            })
            .then((response) => {
               // Handle response data
               console.log(response.data);
               setUserData(response.data);
            })
            .catch((error) => {
               console.error(
                  'There was an error fetching the user data!',
                  error,
               );
               toast.error('No se pudo cargar la información del usuario', {
                  position: 'bottom-right',
                  autoClose: 2500,
               });
            });
      }
   }, [id, token]);
   return (
      <div className="w-full   p-10 flex flex-col gap-8 justify-center">
         <div className="flex md:ml-16 md:pt-2 pt-12  ml-2 flex-col gap-2">
            <div className="text-3xl font-semibold">{t('Profile.account')}</div>
            <div className="text-xl">
               {userData ? userData.name : 'Loading...'}
            </div>
         </div>
         {isOwner ? (
            <Tab.Group vertical>
               <div className="flex flex-col md:flex-row w-full items-center">
                  <Tab.List className="md:h-[500px] h-[100px]">
                     <div className="flex md:flex-col flex-row items-center gap-4  text-left md:items-start md:h-[450px] h-[100px] md:space-y-4 space-y-0 text-xl px-8 ">
                        <Tab className=" aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-72 w-auto">
                           <IdentificationIcon className="size-8" />
                           <span className="hidden text-left md:block">{t('Profile.personalInfo')}</span>
                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center justify-start gap-2 md:w-[355px] w-auto ">
                           <ShieldAlertIcon className="size-8" />

                           <span className="hidden text-left md:block">{t('Profile.security')}</span>
                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-32 w-auto">
                           <Ticket className="  size-8" />
                           <span className="hidden  md:block">
                           {t('Profile.tickets')}
                           </span>

                        </Tab>
                        <Tab className="text-end aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start w-auto">
                           <CircleDollarSign className="size-18 " />
                           <span className="hidden md:block">
                           {t('Profile.earning')}
                           </span>

                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-[300px] w-auto">
                           <Settings className="size-8" />
                           <span className="hidden text-nowrap md:block">
                           {t('Profile.prefs')}
                           </span>

                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-[250px] w-auto">
                           <Plane size={28} />
                           <span className="hidden md:block">
                           {t('Profile.fleet')}
                           </span>

                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-[250px] w-auto">
                           <Plane size={28} />
                           <span className="hidden md:block">
                           {t('Profile.flights')}
                           </span>

                        </Tab>


                     </div>

                  </Tab.List>
                  <Tab.Panels className="h-full px-0 md:px-10 md:pt-2 pt-[2rem] md:border-l-2 border-mobius-golden border-l-0 md:border-t-0 border-t-2 w-full">
                     <Tab.Panel className="h-full">
                        {userData && (
                           <InformacionGeneral
                              name={userData.name}
                              first_surname={userData.first_surname}
                              second_surname={userData.second_surname}
                              email={userData.email}
                              date_of_birth={userData.date_of_birth}
                              telephone={userData.telephone}
                              owner={isOwner}
                           />
                        )}
                     </Tab.Panel>
                     <Tab.Panel className="h-full">
                        <SeguridadClient />
                     </Tab.Panel>
                     <Tab.Panel className="h-full ">
                        <BoletosClient />
                     </Tab.Panel>
                     <Tab.Panel className="h-full ">
                        <EarningComponent/>
                     </Tab.Panel>
                     <Tab.Panel className="h-full">
                        <SettingsClient />
                     </Tab.Panel>
                     <Tab.Panel className="h-full w-full">
                        <PlanesOwner />
                     </Tab.Panel>
                     <Tab.Panel className=" overflow-y-auto no-scrollbar py-8 w-full">
                        {activeFlights.length > 0 ? (
                           activeFlights
                              // .filter(flight => flight.status !== 'completed') // Filter out completed flights
                              .map((flight, index) => (
                                 <ActivePlane
                                    key={index}
                                    flightID={flight.flight.id}
                                    departure={flight.flight.departure_time?.slice(0, 5)}
                                    origin={flight.flight.origin_details?.iata_code}
                                    arrival={flight.flight.arrival_time?.slice(0, 5)}
                                    destination={flight.flight.destination_details?.iata_code}
                                    availableSeats={flight?.flight.available_seats}
                                    Designation={flight?.flight.Designation}
                                    Date={flight?.flight.departure_date}
                                    flightStatus={flight.flight?.status}
                                    planeModal={flight?.plane?.planeModel}
                                    plane={flight?.plane?.registrationNumber}
                                    passengerData={flight?.passengers}
                                    crewData={flight?.crew}
                                 />
                              ))
                        ) : (
                           <p>No flights available.</p>
                        )}
                     </Tab.Panel>
                  </Tab.Panels>
               </div>
            </Tab.Group>
         ) : (
            <Tab.Group vertical>
               <div className="flex flex-col gap-4 md:flex-row w-full items-center">
                  <Tab.List>
                     <div className="flex md:flex-col flex-row items-center gap-4  text-left md:items-start md:h-[450px] h-full md:space-y-4 space-y-0 text-xl px-10 ">
                        <Tab className=" aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-72 w-auto">
                           <IdentificationIcon className="size-8" />
                           <span className="hidden md:block">{t('Profile.personalInfo')}</span>
                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center justify-start gap-2 md:w-[355px] w-auto ">
                           <ShieldAlertIcon className="size-8" />

                           <span className="hidden md:block"> {t('Profile.security')}</span>
                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-32 w-auto">
                           <Ticket className="size-8" />
                           <span className="hidden md:block">
                           {t('Profile.tickets')}
                           </span>

                        </Tab>
                        <Tab className="text-end  aria-selected:text-white aria-selected:bg-mobius-golden p-2 rounded-lg outline-none flex items-center gap-2 justify-start md:w-[300px] w-auto">
                           <Settings className="size-8" />
                           <span className="hidden text-nowrap  md:block">
                           {t('Profile.prefs')}
                           </span>

                        </Tab>
                     </div>
                  </Tab.List>
                  <Tab.Panels className="h-full md:border-l-2 border-l-0 md:border-t-0 border-t-2 pt-4 md:pt-0 border-mobius-golden  px-10">
                     <Tab.Panel className="h-full">
                        {userData && (
                           <InformacionGeneral
                              name={userData.name}
                              first_surname={userData.first_surname}
                              second_surname={userData.second_surname}
                              email={userData.email}
                              date_of_birth={userData.date_of_birth}
                              telephone={userData.telephone}
                              owner={isOwner}
                           />
                        )}
                     </Tab.Panel>
                     <Tab.Panel className="h-full">
                        <SeguridadClient />
                     </Tab.Panel>
                     <Tab.Panel className="h-full w-full">
                        <BoletosClient />
                     </Tab.Panel>
                     <Tab.Panel className="h-full">
                        <SettingsClient />
                     </Tab.Panel>
                  </Tab.Panels>
               </div>
            </Tab.Group>
         )}
      </div>
   );
};

export default ProfilePage;
