import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
function InformacionGeneral({
   name,
   first_surname,
   second_surname,
   email,
   date_of_birth,
   telephone,
   owner,
}) {
   const {t}=useTranslation()
   console.log(
      'InformacionGeneral:',
      name,
      first_surname,
      second_surname,
      email,
      date_of_birth,
      telephone,
      owner,
   );
   return (
      <div className="w-full h-full flex flex-col space-y-2">
         <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('signUp.name')}</h3>
               <span>{name}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('personalInfo.fSurName')}</h3>
               <span>{first_surname}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('personalInfo.lSurName')}</h3>
               <span>{second_surname}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('signIn.email')}</h3>
               <span>{email}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('signUp.dob')}</h3>
               <span>{date_of_birth}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('signUp.phone')}</h3>
               <span>{telephone}</span>
            </div>
            <div className="flex flex-col">
               <h3 className="font-semibold">{t('personalInfo.owner')}</h3>
               <span>{owner ? t('personalInfo.y') : t('personalInfo.n') }</span>
            </div>
         </div>
      </div>
   );
}

export default InformacionGeneral;
