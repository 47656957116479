import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import '../../i18n'

import { useLocation } from "react-router-dom";
const OrderConfirmation=()=>{
    const {t}=useTranslation()
    const resCodeR=localStorage.getItem('reservationCode')
    const routerLocation=useLocation()
    const { sameAsDeparture , reservationCode,isRound} = routerLocation.state || {};

    useEffect(()=>{
        console.log(sameAsDeparture,'is condition')
        console.log(reservationCode,'is res code')
    })


    return(
        <div className="p-8 mt-6 min-h-[400px]">
             <div className="text-4xl font-bold text-center w-[70%] mx-auto ">
            {t('confirmation.line1')}
             </div>
             {isRound?(
                <>
                 {sameAsDeparture?(
                     <div className="text-lg mt-12 text-center ">
                     {t('confirmation.singleRes')} <span className="text-mobius-golden font-bold">
                     {reservationCode} </span> 
                  </div>
                 ):(
                    <div className="text-lg mt-12 text-center ">
                {t('confirmation.departureRes')} <span className="text-mobius-golden font-bold">
                {reservationCode} </span> {t('confirmation.returnRes')} <span className="text-mobius-golden underline">
                {resCodeR} </span>
             </div>
                 )}
              
            </>
             ):(
                <div className="text-lg mt-12 text-center ">
                    {t('confirmation.singleRes')}<span className="text-mobius-golden ml-2 font-bold">
                     {reservationCode}   </span> 
                  </div>
             )}
             
             <div className="text-lg w-[50%] mt-6 text-center mx-auto">
             {t('confirmation.line2')}
             </div>
        </div>
    )
}
export default OrderConfirmation