import React from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy () {
  const { t } = useTranslation();

  return (
    <div className=" mt-6 mx-auto p-6">
        this is privacy policy
      </div>
  );
};

export default PrivacyPolicy;
