import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'

const SeatSelection=({seatOptions,handleSeatSelection,setShowInput,setSelectedSeats,next,selectedSeats,showInput})=>{
    const {t}=useTranslation(

    )
    return(
<div className="h-[100vh] md:px-10 py-32 flex flex-col md:justify-evenly justify-evenly items-center">
                <div className='flex flex-col justify-center items-center gap-4'>
                    <div className="md:text-6xl  w-full text-3xl text-center font-bold">
                      {t('seatSelect.line1')}
                    </div>
                    <div className="md:text-6xl md:w-[80%] w-full text-3xl text-center font-bold">
                    {t('seatSelect.line2')}
                    </div>
                    <div className="md:flex w-5/6 md:flex-row grid grid-cols-4 items-start mt-8 gap-5 mx-6 justify-center">
                        {seatOptions.map((number) => (
                            <button
                                key={number}
                                className={`outline outline-2 md:w-16 md:h-12 w-12 h-8 md:text-2xl text-lg rounded-lg md:p-2 ${selectedSeats === number ? 'bg-mobius-golden text-white' : 'text-mobius-golden'}`}
                                onClick={() => handleSeatSelection(number)}
                            >
                                {number}
                            </button>
                        ))}
                        <button
                            className={`outline outline-2 md:w-10 md:h-12 w-12 h-8 md:text-2xl text-lg rounded-lg md:p-2 ${selectedSeats > 8 ? 'bg-mobius-golden text-white' : 'text-mobius-golden'}`}
                            onClick={() => setShowInput(true)}
                        >
                            +
                        </button>
                        {showInput && (
                            <div className="flex gap-4">
                                <input
                                    type="text"
                                    value={selectedSeats}
                                    onChange={(e) => setSelectedSeats(e.target.value)}
                                    className="outline outline-2 md:w-16 w-12 md:h-12 h-8 text-md rounded-lg p-2 mb-2"
                                    placeholder="Add"
                                />
                                <button
                                    className="bg-mobius-golden h-8 md:text-lg text-sm text-white rounded-lg p-1 md:p-2"
                                    onClick={next}

                                >
                                    {t('seatSelect.add')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    )
}
export default SeatSelection