import React from 'react';
import { useTranslation } from 'react-i18next';

function Terms() {
  const { t } = useTranslation();

  return (
    <div className=" mt-6 mx-auto p-6">
      <h1 className="text-3xl text-black font-bold mb-4">{t('terms_conditions.title')}</h1>

      <p className="text-lg text-black mb-6">{t('terms_conditions.intro')}</p>

      {/* Section 1 */}
      <h3 className="text-lg  font-semibold mt-6">1.{t('terms_conditions.section1.title')}:</h3>
      <p className="text-md text-black  mb-4">{t('terms_conditions.section1.description')}</p>

      {/* Section 2 */}
      <h3 className="text-lg font-bold mt-6">2.{t('terms_conditions.section2.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section2.description')}</p>

      {/* Section 3 */}
      <h3 className="text-lg font-bold mt-6">3.{t('terms_conditions.section3.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section3.description')}</p>

      <h3 className="text-lg font-bold mt-6">4.{t('terms_conditions.section4.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section4.description')}</p>

      <h3 className="text-lg font-bold mt-6">5.{t('terms_conditions.section5.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section5.description')}</p>

      <h3 className="text-lg font-bold mt-6">6.{t('terms_conditions.section6.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section6.description')}</p>

      <h3 className="text-lg font-bold mt-6">7.{t('terms_conditions.section7.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section7.description')}</p>

      <h3 className="text-lg font-bold mt-6">8.{t('terms_conditions.section8.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section8.description')}</p>

      <h3 className="text-lg font-bold mt-6">9.{t('terms_conditions.section9.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section9.description')}</p>

      <h3 className="text-lg font-bold mt-6">10.{t('terms_conditions.section10.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section10.description')}</p>

      <h3 className="text-lg font-bold mt-6">11.{t('terms_conditions.section11.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section11.description')}</p>

      <h3 className="text-lg font-bold mt-6">12.{t('terms_conditions.section12.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section12.description')}</p>

      <h3 className="text-lg font-bold mt-6">13.{t('terms_conditions.section13.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section13.description')}</p>

      <h3 className="text-lg font-bold mt-6">14.{t('terms_conditions.section14.title')}:</h3>
      <p className="text-md text-black mb-4">{t('terms_conditions.section14.description')}</p>
    </div>
  );
};

export default Terms;
