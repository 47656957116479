import { Tab } from '@headlessui/react';
import { useState } from 'react';
import CrewDetails from './CrewDetails';
import FleetDetails from './FleetDetails';
import '../../i18n'
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PlanesOwner() {
  const {t} = useTranslation()
  const [tabs] = useState({
   'Fleet': [
      {
        id: 1,
        content: <FleetDetails/>,
      },
    ],
    'Crew': [
      {
        id: 2,
        content: <CrewDetails/>,
      },
    ],
  });

  return (
    <div className="w-full  px-2  sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl focus:ring-0 bg-gray-200 p-1">
          {Object.keys(tabs).map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                  'ring-mobius-golden  ring-offset-2 ring-offset-none focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-mobius-golden text-white shadow'
                    : 'text-black hover:bg-mobius-golden '
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(tabs).map((tabContent, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white  focus:outline-none focus:ring-2'
              )}
            >
              {tabContent.map((item) => (
                <div key={item.id} className="text-sm text-gray-500">
                  {item.content}
                </div>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
