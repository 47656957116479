import React from 'react';
import {  SecondSection, ThirdSection } from '@/components';
import LogoSection from '@/components/NewLandingPage/LogoSection';
import Inspection from '@/components/NewLandingPage/Inspection';

const NewLandingPage = () => {
   return (
      <div className="w-full bg-white flex flex-col justify-center items-center">
         {/* <Hero />
         <HeroMobile /> */}
         <SecondSection />
         <ThirdSection />
         <LogoSection/>
         <Inspection/>

      </div>
   );
};

export default NewLandingPage;
