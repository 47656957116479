import React, { useEffect, useState } from 'react';
import { MapPinIcon, UsersRound, CalendarDays, Plane } from 'lucide-react';
import Dropdown from './AirportDropdown';
import axios from 'axios';
import { useAuth } from '..';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { saveSearchData, saveReturnData } from './utils/storage'; 
import { useTranslation } from 'react-i18next';
import '../../i18n'

export default function SearchFlight({ setShowSearch, setLoading }) {
    const {t,i18n}=useTranslation()
    let navigate = useNavigate();
    const items = ["Option 1", "Option 2", "Option 3"];
    const [isRound, setIsRound] = useState(false);
    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [passengers, setPassengers] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [returnDate, setReturnDate] = useState("");
    const token = localStorage.getItem('ACCESS_TOKEN');
    const [returnFlights, setReturnFlights] = useState(null);
    
  
    // useEffect(() => {
    //     const getSeats = async () => {


    //         try {
    //             // Second API call for return flight
    //             const response = await axios.get('api/flights/public-flights/1/available_seats');

    //             if (response.status === 200) {
    //                 console.log(response.data)
                

    //             } else {
    //                 throw new Error('Failed to fetch  flight seats data');
                   
    //             }
    //         } catch (error) {
    //             console.error('There was an error fetching the return filtered flights!', error);
    //         }
    //     }

    //     getSeats()

    // })

    const ReturnFlights = async () => {
        if (isRound) {
            try {
                const returnParams = {
                    date: returnDate,
                    origin_city: destination,
                    destination_city: origin,
                    min_seats: passengers
                };

                // Second API call for return flight
                const returnResponse = await axios.get('api/flights/public-flights/filter_flights/', { params: returnParams });

                if (returnResponse.status === 200) {
                    // console.log(returnResponse.data)

                    saveReturnData({ flightData: returnResponse.data })
                    const returnData = returnResponse.data
                    localStorage.setItem('departureDate', departureDate);
                    localStorage.setItem('returnDate', returnDate);
                    localStorage.setItem('isRound', JSON.stringify(isRound));
                      navigate('/book-flight', {
                        state: {
                            departureDate,
                          returnData,
                          isRound,
                          passengers,
                           
                        },
                      });

                    setLoading(false)

                } else {
                    throw new Error('Failed to fetch return flight data');
                }
            } catch (error) {
                console.error('There was an error fetching the return filtered flights!', error);
            }
        } else {
            localStorage.setItem('round', false);
        }
    };



    useEffect(() => {
        console.log('the passengers here in search flight is', passengers)
    })
    const SearchFlight = async () => {
        if(!origin){
            toast.error('Your Origin City is required!', {
                position: 'bottom-right',
                autoClose: 2000,})
            return
        }
        if(!destination){
            toast.error('Destination City is required!', {
                position: 'bottom-right',
                autoClose: 2000,})
            return
        }
        if(!departureDate){
            toast.error('Departure Date is required!', {
                position: 'bottom-right',
                autoClose: 2000,})
            return
        }
        
        if(!passengers){
            toast.error('Number of passengers are required!', {
                position: 'bottom-right',
                autoClose: 2000,})
            return
        }
        if(isRound){
        if(!returnDate){
            toast.error('Returne Date is required!', {
                position: 'bottom-right',
                autoClose: 2000,})
            return
        }}
        
      
        try {
            const params = {
                date: departureDate,
                origin_city: origin,
                destination_city: destination,
                min_seats: passengers
            };
            const response = await axios.get('api/flights/public-flights/filter_flights/', { params });
            if (response.status === 200) {
                // Check if round trip
                setLoading(true)

                // Navigate to the search-flight page after all API calls are complete

                setShowSearch(false);
                saveSearchData({ flightData: response.data })
                localStorage.setItem('departureDate', departureDate);
                localStorage.setItem('returnDate', returnDate);
                localStorage.setItem('origin',origin)
                localStorage.setItem('destination',destination)
                localStorage.setItem('passengers',passengers)
                localStorage.setItem('isRound', JSON.stringify(isRound));
                ReturnFlights()
                if (!isRound) {
                    navigate('/book-flight', { state: {isRound,passengers} });
                }

                setLoading(false)
            } else {
                throw new Error('Failed to fetch outbound flight data');
            }

        } catch (error) {
            console.error('There was an error fetching the filtered flights!', error);
        }
    };






    return (
        <div className="md:w-[90%] w-[95%] flex-wrap  mx-auto  flex flex-col justify-center items-center border-2 border-mobius-dark-gray/20 p-5  gap-2 pt-[40px] rounded-2xl mt-[4rem]">
            <div className="bg-black p-2 text-white flex gap-2 rounded-full">
                <div
                    className={`rounded-full font-bold px-3 py-1 cursor-pointer md:text-md text-base ${!isRound ? 'bg-mobius-golden' : 'hover:bg-mobius-golden'}`}
                    onClick={() => setIsRound(false)}
                >
                    {t('SearchFlight.single')}
                </div>
                <div
                    className={`rounded-full font-bold px-3 py-1 cursor-pointer md:text-md text-base ${isRound ? 'bg-mobius-golden' : 'hover:bg-mobius-golden'}`}
                    onClick={() => setIsRound(true)}
                >
                     {t('SearchFlight.round')}
                </div>
            </div>

            <div className="w-full flex md:flex-row flex-col  my-6 md:gap-4 gap-10 md:justify-evenly justify-normal  items-start ">
                <div className="flex justify-between items-center gap-2 ">
                    <div className="md:p-4 p-2 bg-black rounded-full">
                        <MapPinIcon color="white" className='md:size-4 size-5' />
                    </div>
                    <div className="flex  gap-4 justify-start items-start">
                        <div className='flex items-start flex-col'>
                            <div className="font-bold text-[16px]">
                                {t('SearchFlight.origin')}
                                </div>
                            <div className="flex items-center gap-2  w-full">
                                <Dropdown selectedCity={origin} setSelectedCity={setOrigin} />
                            </div>
                        </div>
                        <div className='flex items-start flex-col'>
                            <div className="font-bold text-[16px]">
                            {t('SearchFlight.dest')}
                                </div>
                            <div className="flex items-center gap-2  w-full">
                                <Dropdown selectedCity={destination} setSelectedCity={setDestination} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="flex justify-between items-center gap-2">
                    <div className="md:p-4 p-2 bg-black rounded-full">
                        <UsersRound color="white" className='md:size-4 size-5' />
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <div className="font-bold  text-[16px]">
                        {t('SearchFlight.passenger')}
                            </div>
                        <input
                            type="number"
                            value={passengers}
                            onChange={(e) => setPassengers(e.target.value)}
                            placeholder=''
                            className="md:mt-0 mt-5 h-[40px] border-2 rounded-lg w-[100px] px-5 md:py-2"
                        />
                    </div>
                </div>
                {/* dates */}
                <div className='flex justify-center gap-4 items-center'>
                    <div className="md:p-4 p-2 bg-black rounded-full">
                        <CalendarDays color="white" className='md:size-4 size-5' />
                    </div>
                    <div className='flex-col gap-6 flex'>
                        <div className="flex flex-col justify-start items-start">
                            <div className="font-bold text-[16px]">
                            {t('SearchFlight.departure')}
                                </div>
                            <input
                                type="date"
                                value={departureDate}
                                onChange={(e) => setDepartureDate(e.target.value)}
                                className="md:mt-0 mt-5 h-[40px] border-2 rounded-lg w-[200px] px-5 md:py-2"
                            />
                        </div>
                        <AnimatePresence>
                            {isRound && (
                                <motion.div
                                    className="flex flex-col justify-start items-start"
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="font-bold text-[16px]">
                                    {t('SearchFlight.return')}
                                        </div>
                                    <input
                                        type="date"
                                        value={returnDate}
                                        onChange={(e) => setReturnDate(e.target.value)}
                                        className="md:mt-0 mt-5 h-[40px] border-2 rounded-lg w-[200px] px-5 md:py-2"
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>

                <div className="flex justify-between items-center bg-mobius-golden rounded-full p-1 md:mx-0 mx-auto transform transition-transform duration-300 hover:scale-110 mt-4  cursor-pointer" onClick={SearchFlight} >
                    <div className="md:p-[6px] p-[6px] bg-mobius-golden rounded-full  border-white border-2">
                        <Plane color="white" className='md:size-6 size-5' />
                    </div>
                    <div className="flex flex-col  justify-start items-start text-white pr-4 pl-2">
                        <div className="font-bold text-nowrap text-[16px]">
                        {t('SearchFlight.book')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
