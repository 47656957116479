import React from 'react';
import { useState } from 'react';
import SearchFlight from './searchFlight';
import { Hero, HeroMobile } from '..';
import LogoSection from './LogoSection';

export default function SecondSection() {
   const [loading, setLoading] = useState(true);
   let [showSearch, setShowSearch] = useState(false)
   
   return (
      <div className="w-full h-full flex  flex-col justify-center items-center p-5 pb-0 px-8 ">
         <SearchFlight setShowSearch={setShowSearch} setLoading={setLoading}  />
         <Hero />
         <HeroMobile />
        {/* <LogoSection/> */}
       
      </div>
   );
}
