import React from "react"
import { useTranslation } from "react-i18next"
import '../../i18n'

const PlaneDetails =({planeType,planeModel,plane1,plane2,plane3,crewData})=>{
  const {t}=useTranslation()
    return(
        <div className="flex flex-col gap-8   px-4 pt-8">
            <div className="font-bold text-xl">
                {planeType}
            </div>
            <div className="flex gap-4">
                <p className="text-black font-bold">{t('planeDetails.model')}</p>
                <p>{planeModel}</p>
            </div>

            <div className="flex flex-col md:flex-row items-center gap-4">
                <img src='https://dummyimage.com/192x160/7a787a/fff' alt="plane 1" className="w-48 h-40 rounded-lg" />
                <img src='https://dummyimage.com/128x160/7a787a/fff' alt="plane 2"  className="w-32 h-40 rounded-lg" />
                <img src='https://dummyimage.com/128x128/7a787a/fff' alt="plane 3"  className="w-32 h-32 rounded-full" />
                <button
          className=' p-2 border-2  border-mobius-golden text-black bg-white hover:bg-mobius-golden hover:text-white rounded-full'
        >
         {t('orderSummary.view')}
        </button>
            </div>
<p className="text-black font-bold">
{t('orderSummary.crew')}
</p>
<div className="flex gap-4">
  {Array.isArray(crewData) && crewData.length > 0 ? (
    crewData.map((crew, index) => (
      <div key={index} className="flex text-center flex-col">
        <p className="text-lg text-black font-bold">{crew.role}</p>
        <p className="text-md">{crew.name}</p>
      </div>
    ))
  ) : (
    <></> 
  )}
</div>   </div>
    )
}
export default PlaneDetails