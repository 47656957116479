import React from "react";
import { useTranslation } from "react-i18next";
import '../../i18n'

const EarningCard = ({ icon, amount, desc, text, progress, onClick ,details}) => {
    const {t}=useTranslation()
    return (
        <div
            onClick={onClick}  // Handle click to open modal
            className={`flex group rounded-lg bg-gray-100 ${!text ? 'hover:bg-mobius-golden hover:text-white' : ''} items-center gap-6 shadow-md py-6 cursor-pointer w-full p-4`}
        >
            {text && (
                <div className="w-[150px] flex flex-col gap-2 items-center h-[150px]">
                    {progress}
                    <p className="text-center">{t('earnings.tSales')}</p>
                </div>
            )}

            <div className="flex flex-col gap-2">
                {text ? (
                    <p className="text-md text-black font-bold">{t('earnings.myEarnings')}</p>
                ) : (
                    <>{icon}</>
                )}

                <p className={`text-xl ${!text ? 'group-hover:text-white' : ''} font-bold`}>$ {amount}</p>
                <p className={`${!text ? 'group-hover:text-white' : ''}`}>{desc}</p>
                <div className="h-[15px]">
                <p className={`${!text ? 'group-hover:text-blue-100 group-hover:block' : ''} hidden underline h-[15px]`}>{details}</p>

                </div>
            </div>
        </div>
    );
};

export default EarningCard;
