import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import LogoTransp from '../../components/assets/LogoTransp.png';
import DatePicker from 'react-datepicker';
import { Globe } from 'lucide-react';
// import { GlobeAltIcon } from '@heroicons/react/24/solid';
import MenuUserMobile from '../../components/Navbar/menu-user-mobile';
import CityDropdown from './CityDropdown';
import { ArrowLeftRight } from 'lucide-react';
import { Minus } from 'lucide-react';
import { Filter } from 'lucide-react';
import { Dialog } from '@headlessui/react';

const NavbarDesktop = ({ startDate, setStartDate, endDate, setEndDate, setOrigin, setDestination, destination, origin }) => {
   const [isOpen, setIsOpen] = useState(false);
 
 
   function closeModal() {
     setIsOpen(false);
   }
 
   function openModal() {
     setIsOpen(true);
   }
 
   return (
      <div className="w-full h-16 flex px-2 bg-black justify-between md:hidden py-2 items-center fixed z-50">
         <div className='flex gap-4 items-center'>

         <NavLink
            to="/"
           
          >
            <img src={LogoTransp} alt="Logo" className="w-16" />
          </NavLink>
            <div onClick={openModal} className='flex items-center gap-2  cursor-pointer'>
               <Filter size={22} className='text-white'/>
               <div className='text-md text-white ' >
               Filter
               </div>
               
               
               </div>

            <Dialog open={isOpen} onClose={closeModal} className="relative z-50">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-lg bg-white rounded-lg p-6 shadow-lg">
            <Dialog.Title
              as="h3"
              className="text-xl font-medium text-gray-900"
            >
              Select Flight Details
            </Dialog.Title>

            <div className="mt-4 flex flex-col gap-4">
              <div className="flex flex-col my-4 w-full justify-center">
                <div className="flex items-center justify-center p-2">
                  <DatePicker
                    selected={startDate}
                    dateFormat="dd MMM"
                    className="outline-none text-black bg-gray-100 text-center w-[80px] p-2 rounded-md"
                    onChange={(date) => setStartDate(date)}
                  />
                  <div>
                    <Minus size={12} className="text-black mx-2" />
                  </div>
                  <DatePicker
                    selected={endDate}
                    dateFormat="dd MMM"
                    placeholderText="Date"
                    className="outline-none text-black bg-gray-100 text-center w-[80px] p-2 rounded-md"
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>

              <div className="flex flex-col my-4 border-l-2 border-gray-200 w-full justify-center">
                <div className="flex items-center justify-center p-2">
                  <CityDropdown
                    setSelectedCity={setOrigin}
                    selectedCity={origin}
                    className="text-black"
                  />
                  <div>
                    <ArrowLeftRight size={18} className="text-black mx-2" />
                  </div>
                  <CityDropdown
                    setSelectedCity={setDestination}
                    selectedCity={destination}
                    className="text-black"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <button
                type="button"
                className="bg-white text-black px-4 py-2 rounded-md hover:bg-mobius-golden focus:outline-none focus:ring-2 focus:bg-mobius-golden"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>


         </div>
         <div className="flex gap-4 font-medium text-base items-center justify-center">
            <div className="flex space-x-4 items-center">
               <Globe size={20} color="white" />
               <MenuUserMobile />
            </div>
         </div>
      </div>
   );
};

export default NavbarDesktop;
