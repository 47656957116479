import React, { useState, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next
import CrewRegister from '../OwnerReg/CrewSelection';
import PreviousNext from './PreviousNext';


const CrewSelection = ({ selectedCrewIds, setSelectedCrewIds,next,previous }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [CrewData, setCrewData] = useState([]);
  const [selectedCrew, setSelectedCrew] = useState([]);
  const [query, setQuery] = useState('');
  const [showCrewRegister, setShowCrewRegister] = useState(false);

  // Function to toggle CrewRegister visibility
  const handleToggleCrewRegister = () => {
    setShowCrewRegister(prevState => !prevState); // Toggle between true/false
  };
    // crew details
    const [crewName, setCrewName] = useState('');
    const [crewLastName, setCrewLastName] = useState('');
    const [crewLicense, setCrewLicense] = useState('');
    const [crewRole, setCrewRole] = useState('First Officer');
 
  //   Register new crew member
  const handleCrewData = async () => {
    if (!crewName) {
       toast.error('Name of crew member is required.', {
          position: 'bottom-right',
          autoClose: 2500,
       });
       return;
    }
    if (!crewRole) {
       toast.error('Role of crew member is required.', {
          position: 'bottom-right',
          autoClose: 2500,
       });
       return;
    }
    if (!crewLicense) {
       toast.error('Role of crew member is required.', {
          position: 'bottom-right',
          autoClose: 2500,
       });
       return;
    }
    axios
       .post(`/api/crew/crew/`, {
          name: crewName,
          surname: crewLastName,
          role: crewRole,
          licenseNumber: crewLicense
       }, {
          headers: {
             'Authorization': `Bearer ${token}`
          }
       })
       .then((response) => {

          console.log(response.data)
          toast.success('You have registered your crew member Successfully!', {
             position: 'bottom-right',
             autoClose: 2000,
          });
          handleToggleCrewRegister()

       })
       .catch((error) => {
          console.error('Crew getting error', error.response ? error.response.data : error.message);

       });

 };

 useEffect(() => {
  // Define the fetch function
  const fetchCrewData = async () => {
    try {
      const response = await axios.get('api/crew/crew/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCrewData(response.data);
    } catch (error) {
      console.error('There was an error fetching crew data!', error);
    }
  };

  // Only start polling if the token is available
  if (token) {
    // Fetch the data immediately, then set up polling
    fetchCrewData();
    
    const intervalId = setInterval(() => {
      fetchCrewData();
    }, 4000); // Polling every 10 seconds (10000 ms)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }
}, [token, setCrewData]);

  useEffect(() => {
    console.log('the ids for crew is', selectedCrewIds);
  }, [selectedCrewIds]);

  const handleSelectCrew = (crew) => {
    if (selectedCrew.length < 3) {
      if (!selectedCrew.find((member) => member.id === crew.id)) {
        setSelectedCrew([...selectedCrew, crew]);
        setSelectedCrewIds([...selectedCrewIds, crew.id]); // Add crew ID to the array
      }
    } else {
      alert(t('crewSelection.maxSelectionAlert')); // Replace with localization
    }
  };

  const handleRemoveCrew = (id) => {
    setSelectedCrew(selectedCrew.filter((member) => member.id !== id));
    setSelectedCrewIds(selectedCrewIds.filter((crewId) => crewId !== id)); // Remove crew ID from the array
  };

  return (
    <div className="flex h-[80vh] flex-col mt-8 justify-center items-center md:px-28 text-center">
       {showCrewRegister && (
        <div>
       
        <CrewRegister
          crewName={crewName}
          crewLastName={crewLastName}
          crewRole={crewRole}
          setCrewName={setCrewName}
          setCrewLastName={setCrewLastName}
          setCrewLicense={setCrewLicense}
          setCrewRole={setCrewRole}
          crewLicense={crewLicense}
        />
        <button
                        onClick={handleCrewData}
                        className="outline outline-2 outline-mobius-golden text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-12 text-xl rounded-xl p-2"
                     >
                        {t('crewSelection.reg')}
                     </button>
        
        </div>
      )}
      {!showCrewRegister && (
        <>
      <div className="md:mb-0 mb-5">
        <div className="font-bold md:text-5xl mt-8 text-3xl">{t('crewSelection.heading')}</div>
        <div className="font-light px-4 text-gray-800 md:mt-10 mt-2 mb-8">
          {t('crewSelection.description')} <span  onClick={handleToggleCrewRegister} className='hover:underline font-bold cursor-pointer text-mobius-golden'>{t('crewSelection.reg')}</span>
        </div>
      </div>
      <div className="mb-4 relative w-[90%] md:w-1/2">
        <Combobox onChange={handleSelectCrew}>
          <Combobox.Input
            className="px-4 py-2 border-0 border-b-2 text-black focus:outline-none focus:border-b-2 w-full"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={t('crewSelection.placeholder')}
          />
          <Combobox.Options className="mt-1 w-[100%] absolute bg-gray-200 z-[999] border rounded-md max-h-60 overflow-auto">
            {CrewData?.filter((crew) =>
              crew.name.toLowerCase().includes(query.toLowerCase())
            ).map((crew) => (
              <Combobox.Option
                key={crew.id}
                value={crew}
                className={({ active }) =>
                  `px-8 py-2 cursor-pointer flex justify-between  ${active ? 'bg-mobius-golden text-white' : ''}`
                }
                onClick={() => handleSelectCrew(crew)}
              >
                <span className={({ active }) =>
                  `px-4 py-2 cursor-pointer  ${active ? 'bg-mobius-golden text-white' : ''}`
                }>{crew.name}</span>
                <span className={({ active }) =>
                  `px-4 py-2 cursor-pointer  ${active ? 'bg-mobius-golden text-white' : ''}`
                }>{crew.role}</span>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>
      </div>
      <div className="mt-4 w-[90%] md:w-1/2">
        {selectedCrew.map((crew) => (
          <div
            key={crew.id}
            className="grid md:grid-cols-4 gap-4 md:gap-0 grid-cols-2 justify-center items-center bg-gray-100 p-2 mb-4 rounded-md shadow-md"
          >
            <div className='flex gap-2'>
              <p className="font-bold ">{crew.name}</p>
              <p className="font-bold ">{crew.surname}</p>
            </div>
            <div className="text-sm text-right md:text-left font-bold text-gray-600">{t('crewSelection.role')}: {crew.role}</div>
            <div className="text-sm font-bold text-gray-600 text-nowrap">{t('crewSelection.licenseNo')}: {crew.licenseNumber}</div>
            <div>
              <p className='cursor-pointer text-right text-sm font-bold text-gray-600 text-nowrap hover:text-red-500' onClick={() => handleRemoveCrew(crew.id)}>{t('crewSelection.delete')}</p>
            </div>
          </div>
        ))}
        {selectedCrew.length < 1 && (
          <p className="text-red-500 mt-2">
            {t('crewSelection.noCrewSelected')}
          </p>
        )}
      </div>
      <PreviousNext innernext={next} innerprevious={previous} />
      </>
       )}


      
    </div>
  );
};

export default CrewSelection;
