import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../../i18n'
import { useTranslation } from "react-i18next";

function ContactUs() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
 const {t}=useTranslation()
  async function sendContactMessage() {
    const url = '/api/flights/contact/';
    
    const payload = {
      name: `${firstName} ${lastName}`, // Combine first and last name
      email: email,
      subject: subject,
      message: message
    };

    try {
      const response = await axios.post(url, payload);
      if (response.status === 201) {
        toast.success("Message sent successfully!", {
            position: 'bottom-right',
            autoClose: 2500,
         });;
        // Clear all input fields after success
        setFirstName('');
        setLastName('');
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        toast.error("Error sending message. Please try again.", {
            position: 'bottom-right',
            autoClose: 2500,
         });;
      }
    } catch (error) {
      console.error("Error sending message:", error.response ? error.response.data : error.message);
      toast.error("Failed to send message.", {
        position: 'bottom-right',
        autoClose: 2500,
     });;
    }
  }

  return (
    <div className="p-5 mt-6 w-full py-[5rem]">
      <ToastContainer />
      <div className="text-4xl text-center">{t('contactUs.first_line')}</div>
      <div className="text-xl text-center">{t('contactUs.second_line')}</div>
      <div className="w-[400px] mt-[4rem] flex flex-col items-center gap-2 m-auto">
        <div className="flex md:flex-row flex-col gap-2">
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t('bookFlight.fName')}
            className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
          />
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t('bookFlight.lName')}
            className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
          />
        </div>

        <div className="flex md:flex-row flex-col gap-2">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('bookFlight.email')}
            className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
          />
           <input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder={t('contactUs.subject')}
          className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
        />
          
        </div>

       

        <textarea
          id="message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="5"
          className="block md:min-w-[510px] w-[250px] px-4 py-2 border border-2 border-gray-300 rounded-md shadow-sm mt-4 md:mt-1 focus:border-blue-500"
          placeholder={t('contactUs.message')}
        ></textarea>

        <button
          onClick={sendContactMessage}
          className="outline outline-2 outline-mobius-golden mt-4 text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-10 text-xl rounded-xl p-2"
        >
          {t('contactUs.submit')}
        </button>
      </div>
    </div>
  );
}

export default ContactUs;
