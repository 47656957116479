import React from "react"
import image from '../assets/grid2.png'
import '../../i18n'
import { useTranslation } from "react-i18next"
const Inspection=()=>{
    const {t}=useTranslation()
    return(
        <div className="p-8 mt-4 md:px-16 px-10 flex gap-8 md:flex-row flex-col  items-center">
            <div className="text-md md:w-1/2 text-center md:text-left w-full ">

          
            <span className="font-bold text-lg ml-2">
               {t('inspection.heading')}
               </span> 
               {t('inspection.text')}
            </div>
            <img src={image} alt="quality" className="md:w-[40%] w-full" />
        </div>
    )
}
export default Inspection