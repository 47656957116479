import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
const CrewSelection = ({ crewName, setCrewName, crewLastName, setCrewLastName, crewLicense, setCrewLicense, crewRole, setCrewRole }) => {
   const { t } = useTranslation()
   return (
      <div>
         <div className="md:text-4xl text-3xl md:mt-[7rem] mt-4  font-bold text-center mt-6">
            {t('crewDetails.introText')}  <span className='block md:hidden'> {t('crewDetails.meber')} </span>
         </div>
         <div className="md:text-4xl hidden md:block text-3xl  font-bold text-center mt-2">
            {t('crewDetails.member')}
         </div>
         <div className="md:h-[40vh] h-[100vh]  mt-4  flex md:flex-row flex-col md:justify-center justify-evenly items-center gap-10">
            <div className="flex flex-col justify-start md:gap-6 md:h-1/2 items-center">
               <div className="flex flex-col gap-5 items-center text-center">
                  <div className=" text-xl font-bold">
                     {t('crewDetails.name')}
                  </div>
               </div>
               <input
                  type="text"
                  name="fleet-name"
                  id="fleet-name"
                  placeholder={t('crewDetails.nameEnter')}
                  value={crewName}
                  onChange={(e) => setCrewName(e.target.value)}
                  className="md:w-5/6 w-3/5 md:mt-0 mt-5 h-[40px]  rounded-lg  md:w-[200px] w-[200px] bg-mobuis-gray px-5 md:py-2"
               />
            </div>
            <div className="flex flex-col justify-start md:gap-6 md:h-1/2 items-center">
               <div className="flex flex-col gap-5 items-center text-center">
                  <div className=" text-xl font-bold">
                     {t('crewDetails.lName')}
                  </div>
               </div>
               <input
                  type="text"
                  name="fleet-lname"
                  id="fleet-lname"
                  value={crewLastName}
                  onChange={(e) => setCrewLastName(e.target.value)}
                  placeholder={t('crewDetails.lNameEnter')}
                  className="md:w-5/6 w-3/5 md:mt-0 mt-5 h-[40px]  rounded-lg  md:w-[200px] w-[200px] bg-mobuis-gray px-5 md:py-2"
               />
            </div>
            <div className="flex flex-col justify-start md:gap-6 md:h-1/2 items-center">
               <div className="flex flex-col gap-5 items-center text-center">
                  <div className=" text-xl font-bold">
                     {t('crewDetails.license')}
                  </div>
               </div>
               <input
                  type="text"
                  name="fleet-licence"
                  id="fleet-licence"
                  value={crewLicense}
                  onChange={(e) => setCrewLicense(e.target.value)}
                  placeholder={t('crewDetails.licenseEnter')}
                  className="md:w-5/6 w-3/5 md:mt-0 mt-5 h-[40px]  rounded-lg  md:w-[200px] w-[200px] bg-mobuis-gray px-5 md:py-2"
               />
            </div>
            <div className="flex flex-col justify-start md:gap-6 md:h-1/2 items-center">
               <div className="flex flex-col md:gap-5  gap-2 items-center text-center">
                  <div className=" text-xl font-bold">
                     {t('crewDetails.role')}
                  </div>
               </div>
               <select
                  name="model"
                  id="model"
                  value={crewRole}
                  onChange={(e) => setCrewRole(e.target.value)}
                  className="md:h-1/6 min-h-[40px] max-h-[40px] rounded-lg bg-mobuis-gray px-5 md:w-[200px] w-[200px] md:mt-0 mt-5">
                  <option value='First Officer'> {t('crewDetails.role1')}</option>
                  <option value='Captain'>{t('crewDetails.role2')}</option>

               </select>
            </div>
         </div>

      </div>
   )
}
export default CrewSelection;